<template>
    <form @submit="onSubmit" class="changePasswordForm" id="changePasswordForm">
        <div class="title_loginin">Troca de Senha</div>

        <label for="userPassword">Senha atual
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input v-model="userPassword" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userPassword" name="userPassword" placeholder="Digite sua senha atual" required>

        <label for="userNewPassword">Nova senha
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input v-model="userNewPassword" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userNewPassword" name="userNewPassword" placeholder="Digite a nova senha" required>

        <label for="userNewPasswordConfirm">Nova senha (confimação)
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input v-model="userNewPasswordConfirm" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userNewPasswordConfirm" name="userNewPasswordConfirm" placeholder="Repita a senha para confirmar" required>

        <div v-if="!this.validPassword">
            <ul>
                <li class="corrections" v-for="(correction, index) in this.correctionsNeeded" :key="index">
                    <img :src="icon" alt="My Icon" /> {{ correction }}
                </li>
            </ul>
        </div>
        <div>
            <button :class="{ 'button__form': !is_Submitting, 'button__form_alt': is_Submitting }" type="submit" :disabled="is_Submitting">Trocar Senha</button>
            <button class="button__form" type="reset" @click="hideUserChangePassword">Cancelar</button>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import icon from '@/assets/img/triangle_exclamation.svg';
import Cookies from 'js-cookie';

const toast = useToast();

export default defineComponent({
    name: 'userDelete',
    computed: {
        is_Submitting() {
            return (this.isSubmitting)
        },
    },
    methods: {
        hideUserChangePassword() {
            this.$store.commit('touggleShowUserChangePassword');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
        },
        togglePasswordVisibility() {
            this.$store.commit('togglePasswordVisibility');
        },
        resetForm() {
            this.userEmail = null;
            this.userPassword = null;
            this.userNewPassword = null;
            this.userNewPasswordConfirm = null;
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            this.validPassword = true;
            this.correctionsNeeded = [];

            if (this.userNewPassword != this.userNewPasswordConfirm) {
                this.correctionsNeeded.push('A confirmação da senha não confere!');
                this.validPassword = false;
                this.isSubmitting = false;
            }

            if (this.validPassword) {

                const passwordToChange = {
                    password: this.userPassword,
                    newPassword: this.userNewPassword
                };

                try {
                    const response = await this.$store.dispatch('changeUserPassword', passwordToChange);

                    if (response) {
                        toast.success(`${response.user.name.split(" ")[0]}, sua senha foi trocada com sucesso!\nVocê precisa fazer login novamente.`, {timeout: 6000});
                        this.resetForm();
                        this.$store.dispatch('logoutUser');
                        Cookies.remove('user_token', { path: '' });
                        this.$store.commit('touggleShowUserChangePassword');
                    }

                } catch (error) {
                    console.log(error);
                } finally {
                    this.isSubmitting = false;
                }
            }
        }
    },
    data() {
        return {
            icon,
            userPassword: null,
            userNewPassword: null,
            userNewPasswordConfirm: null,
            phraseConfirm: null,
            validPhrase: true,
            correctionsNeeded: [],
            isSubmitting: false,
        }
    },
})

</script>

<style scoped>

.title_loginin {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}
.changePasswordForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.changePasswordForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 235px;
    text-align: left;
}

.changePasswordForm select,
.changePasswordForm input[type="email"],
.changePasswordForm input[type="password"],
.changePasswordForm input[type=text] {
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.changePasswordForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}
.corrections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    gap: 6px;
    color: var(--laranja-cv);
}
</style>
<template>
    <div class="bottomBar">
        <div class="bottomBar__item" @click="showTermsAndConditions">
            Termos e Condições de Uso
        </div>
        <div class="bottomBar__item" @click="ShowPrivacyPolicy">
            Política de Privacidade
        </div>
        <div class="bottomBar__item">
            <a href="#">Copyright © ClassiVela - 2024</a>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        showTermsAndConditions() {
            this.$store.commit('hideAllContentPanel');
			this.$store.commit('toggleShowTermsAndConditions');
		},
        ShowPrivacyPolicy() {
            this.$store.commit('hideAllContentPanel');
			this.$store.commit('toggleShowPrivacyPolicy');
		}
    },
})
</script>

<style scoped>
.bottomBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #0B3954;
}

.bottomBar__item {
    text-align: center;
    vertical-align: middle;
    flex-grow: 1;
    padding: 20px;
}

a {
    color:azure;
}

a:hover {
	color: #6DCFF6;
}
</style>
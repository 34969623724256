<template>
    <div class="photo-component">
        <div v-for="(photo, index) in displayPhotos" :key="index" class="image-container">
            <img :src="photo.url" :alt="`Image ${index + 1}`" class="uploaded-image" />
            <button type="button" @click="removeImage(index, photo.type)" class="remove-button">
                <img class="remove__photo" src="../assets/img/minus_sign.svg" alt="Remover imagem" />
            </button>
        </div>
        <input v-if="this.photosKept.length + this.photosAdded.length < 6 && !this.showCropper" type="file" id="file-upload" @change="triggerCrop"
            hidden />
        <label v-if="this.photosKept.length + this.photosAdded.length < 6 && !this.showCropper" class="adPhoto" for="file-upload">
            <img src="../assets/img/no_pic.jpg" alt="Upload Image" class="upload-button" />
        </label>
        <Cropper v-if="this.showCropper" class="cropper" ref="cropper" :src="tempImageUrl" @new-image="addImage" :stencil-props="{ aspectRatio: 16/9, minAspectRatio: 16/9, maxAspectRatio: 16/8}"/>
        <div v-if="this.showCropper" class="button__crop" @click="confirmCrop">
            <p>Cortar</p>
        </div>
    </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'; // Adicione esta linha para importar a função UUID
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import noPic from "../assets/img/no_pic.jpg";

const imageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;

export default {
    components: {
        Cropper,
    },
    data() {
        return {
            displayPhotos: [],
            showCropper: false,
            tempImageUrl: noPic,
        };
    },
    computed: {
        ...mapState(['photosKept', 'photosDeleted', 'photosAdded', 'adFilesDeleted', 'adFilesAdded', 'selectedAd'])
    },
    methods: {
        ...mapMutations(['setPhotosDeleted', 'setPhotosAdded', 'setAdFilesAdded']),
        ...mapActions(['generatePhotosUpdateValidation']),
        generateFileName(id) {
            let uuid = uuidv4(); // Gera um UUID
            return `${id}_adImage_${uuid}.jpg`;
        },
        getImageUrl(photoName) {
            return imageStorageLink + '/' + photoName;
        },
        triggerCrop(event) {
            let file = event.target.files[0];
            let reader = new FileReader();

            reader.onload = () => {
                this.tempImageUrl = reader.result;
                this.showCropper = true;
            };
            
            reader.readAsDataURL(file);
        },
        async confirmCrop() {
            const { canvas } = this.$refs.cropper.getResult();
            this.image = canvas.toDataURL();

            // Converte Base64 de volta para um objeto Blob
            const blob = await (await fetch(this.image)).blob();

            // Cria um novo objeto de arquivo a partir do Blob
            const fileName = this.generateFileName(this.selectedAd._id);
            const file = new File([blob], fileName, { type: blob.type });

            this.showCropper = false;
            this.addImage(file, this.image);
        },
        addImage(file, image) {
            let fileName = file.name;
    
            this.setPhotosAdded(fileName);
            this.setAdFilesAdded(file);
            this.displayPhotos.push({ url: image, type: 'added' });
               
            // Resetar estado do cropper
            this.showCropper = false;
            this.tempImageUrl = noPic;
        },
        removeImage(index, type) {
            let photoList, fileList;

            if (type === 'kept') {
                photoList = this.photosKept.slice(0);
                fileList = this.photosKept.slice(0);
                this.setPhotosDeleted(photoList[index]);
            } else {
                photoList = this.photosAdded.slice(0);
                fileList = this.adFilesAdded.slice(0);
                this.setPhotosAdded(photoList.filter((_, i) => i !== index));
                this.setAdFilesAdded(fileList.filter((_, i) => i !== index));
            }

            photoList.splice(index, 1);
            fileList.splice(index, 1);
            this.displayPhotos.splice(index, 1);
            this.photosKept.splice(index, 1);

            // Atualizar a array photosUpdateValidation para refletir as mudanças
            this.$store.dispatch('generatePhotosUpdateValidation');
        }
    },
    mounted() {
        this.displayPhotos = [...this.photosKept.map(photo => ({ url: this.getImageUrl(photo), type: 'kept' })), ...this.photosAdded.map(photo => ({ url: photo, type: 'added' }))];
    }
}
</script>

  
<style>

.cropper {
	height: 174px;
	width: 310px;
	background: #DDD;
}

.photo-component {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: left;
}

.image-container {
    position: relative;
    width: 320px;
    height: 185px;
    border-radius: 1%;
    margin-bottom: 10px;
    border: solid;
    border-width: 6px;
}

.remove__photo {
    background-color: var(--branco-cv);
    padding: 5px;
}

.remove__photo:hover {
    background-color: var(--laranja-cv);
}

.uploaded-image {
    width: 100%;
    height: auto;
}

.remove-button {
    position: absolute;
    top: -1px;
    right: -6px;
    background: transparent;
    color: red;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.upload-button {
    width: 310px;
    height: auto;
    cursor: pointer;
}

.adPhoto {
    width: 320px;
    height: 185px;
    border-radius: 1%;
    margin-bottom: 10px;
    border: solid;
    border-width: 6px;
}

.adPhoto:hover {
    border: dotted;
    border-width: 5px;
}

.button__crop {
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 5px 2px 5px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    margin-left: 90px;
    transition: background-color 0.1s ease-in-out;
}

.button__crop:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
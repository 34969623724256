<template>
	<div :class="{ 'container__alt': !isSmallPage || categoriesVisible, 'container': isSmallPage && !categoriesVisible }">
		<div
			:class="{ 'leftBar__panelOn': !isSmallPage || categoriesVisible, 'leftBar__panelOff': isSmallPage && !categoriesVisible }">
			<LeftBar/>
		</div>
		<ContentPanel/>
	</div>
</template>

<script>

import { defineComponent } from 'vue';
import LeftBar from "./leftBar.vue";
import ContentPanel from "./contentPanel.vue";

export default defineComponent({
	name: 'middleScreen',
	components: {
		LeftBar,
		ContentPanel
	},
	created() {
		window.addEventListener('resize', () => {
			this.updateSmallPage();
		});

		this.updateSmallPage();
	},
	methods: {
		updateSmallPage() {
			if (window.innerWidth < 600 && !this.$store.state.smallPage) {
				this.$store.commit('setSmallPageOn');
				if (this.$store.state.showCategories)
					this.$store.commit('touggleShowCategories');
			}

			if (window.innerWidth >= 600 && this.$store.state.smallPage) {
				this.$store.commit('setSmallPageOff');
				if (!this.$store.state.showCategories)
					this.$store.commit('touggleShowCategories');
			}
		}
	},
	computed: {
		categoriesVisible() {
			return this.$store.state.showCategories;
		},
		isSmallPage() {
			return this.$store.state.smallPage;
		}
	}
})

</script>


<style scoped>
.container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	padding-top: 12px;
	padding-bottom: 12px;
	height: auto;
}

.container__alt {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content:flex-start;
	align-items: stretch;
	padding-top: 12px;
	padding-bottom: 12px;
	height: auto;
}

.leftBar__panelOn {
	background-color: var(--azul-escuro-cv);
	width: 230px;
	display: list-item;
	color: var(--creme-cv);
	padding: 10px;
	margin-left: 10px;
}

.leftBar__panelOff {
	display: none;
}
</style>
<template>
    <form @submit="onSubmit" class="deleteProfileForm" id="deleteProfileForm">
        <div class="title_loginin">Excluir Perfil</div>
        <label for="userEmail">Endereço de e-mail</label>
        <input v-model="userEmail" type="email" id="userEmail-login" name="userEmail" placeholder="Digite seu endereço de e-mail" required>

        <label for="userPassword">Senha
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input v-model="userPassword" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userPassword-login" name="userPassword" placeholder="Digite sua senha" required>
        
        <label for="userPhrase">Frase de confirmação</label>
        <p>Digite a frase "QUERO EXCLUIR MEU PERFIL"</p>
        <input v-model="phraseConfirm" type="text" id="userPhraseConfirm" name="userPhraseConfirm" autocomplete="off" placeholder="Digite: QUERO EXCLUIR MEU PERFIL" required>
        <p>ATENÇÃO: A exclusão do perfil irá eliminar todos os seus dados e é irreversível!</p>
        <div v-if="!this.validPhrase">
            <ul>
                <li class="corrections" v-for="(correction, index) in this.correctionsNeeded" :key="index">
                    <img :src="icon" alt="My Icon" /> {{ correction }}
                </li>
            </ul>
        </div>
        <div>
            <button class="button__delete" type="submit">EXCLUIR PERFIL</button>
            <button class="button__form" type="reset" @click="hideUserDelete">Cancelar</button>
            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate 
                :size="50" 
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import icon from '@/assets/img/triangle_exclamation.svg';
import Cookies from 'js-cookie';

const toast = useToast();
const phraseConfirmString = process.env.VUE_APP_DELETE_PROFILE_PHRASE_CONFIRM

export default defineComponent({
    name: 'userDelete',
    methods: {
        hideUserDelete() {
            this.$store.commit('touggleShowUserDelete');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
        },
        togglePasswordVisibility() {
            this.$store.commit('togglePasswordVisibility');
        },
        resetForm() {
            this.userEmail = null;
            this.userPassword = null;
            this.phraseConfirm = null;
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            this.validPhrase = true;
            this.correctionsNeeded = [];

            if (this.phraseConfirm == phraseConfirmString) {

                const userToDelete = {
                    email: this.userEmail,
                    password: this.userPassword,
                };

                try {
                    const response = await this.$store.dispatch('deleteUser', userToDelete);

                    if (response) {
                        toast.success(`Seu perfil foi excluído com sucesso!\nFoi bom ter você conosco, obrigado!`, {timeout: 6000});
                        this.resetForm();
                        this.$store.dispatch('logoutUser');
                        Cookies.remove('user_token', { path: '' });
                        this.$store.commit('touggleShowUserDelete');
                    }

                } catch (error) {
                    console.log(error);
                } finally {
                    this.isSubmitting = false;
                }

            } else {
                this.correctionsNeeded.push('Digite a frase de confirmção corretamente.');
                this.validPhrase = false;
                this.isSubmitting = false;
            }
        }
    },
    data() {
        return {
            icon,
            userEmail: null,
            userPassword: null,
            phraseConfirm: null,
            validPhrase: true,
            correctionsNeeded: [],
            isSubmitting: false,
        }
    },
})

</script>

<style scoped>

.title_loginin {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}
.deleteProfileForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.deleteProfileForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.deleteProfileForm select,
.deleteProfileForm input[type="email"],
.deleteProfileForm input[type="password"],
.deleteProfileForm input[type=text] {
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.deleteProfileForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__delete {
    font-family: Ubuntu;
    font-size: 18px;
    width: 160px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--vermelho-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__delete:hover {
    background-color: var(--vermelho-cv);
    color: var(--branco-cv);
}

.corrections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    gap: 6px;
    color: var(--laranja-cv);
}
</style>
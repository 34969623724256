<template>
    <div>
        <div>
            <div v-if="!getIsBacker">
                <p class="text_apoiase">Conheça o projeto!</p>
                <p class="text_apoiase">Seja um apoiador no</p>
                <div class="button">
                    <a id="div_link" href="https://apoia.se/classivela" target="_blank">
                        <img class="button__apoiase" src="../assets/img/apoiase.png">
                    </a>
                </div>
                <p class="text_apoiase">Com um pequeno apoio você passa a ter acesso completo ao site.</p>
                <p class="text_apoiase">Clique no botão acima e saiba mais.</p>
            </div>
            <div class="button__adsManager" @click="home" v-show="this.$store.state.showAdsManager || this.$store.state.showFavouritesAds">
                MENU INICIAL
            </div>
            <div class="button__adsManager" @click="adsManager"
                v-show="!this.$store.state.showAdsManager && !this.$store.state.showFavouritesAds && this.$store.state.userActive">
                ANUNCIAR
            </div>
            <div class="button__favourites" @click="showFavourites" v-show="!this.$store.state.showFavouritesAds && !this.$store.state.showAdsManager && this.$store.state.userActive">
                FAVORITOS
            </div>
            <div v-show="!this.$store.state.showFavouritesAds && !this.$store.state.showAdsManager" class="title">Categorias</div>
            <ul v-show="!this.$store.state.showFavouritesAds && !this.$store.state.showAdsManager"  class="category">
                <li :class="{ 'category__itens-alt': getAdsSelectedFilterCategory == category.name, 'category__itens': getAdsSelectedFilterCategory != category.name }"
                    v-for="category in categories" :key="category.id" @click="selectCategory(category.name)">{{
                        category.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default defineComponent({
    computed: {
        ...mapGetters(['getAdsSelectedFilterCategory', 'getIsBacker']),
        ...mapState(['user']),
    },
    methods: {
        home() {
            this.$store.commit('hideAllContentPanel');
            this.$store.commit('touggleShowAdvertiseButton');
            this.$store.commit('touggleShowSlider');
            this.$store.commit('toggleHideFavouritesAds');
        },
        ...mapActions(['loadAdsByFilter', 'loadAdsFavourites']),
        async selectCategory(category) {
            if (this.$store.state.showTermsAndConditions) this.$store.commit('toggleShowTermsAndConditions');
            if (this.$store.state.ShowPrivacyPolicy) this.$store.commit('toggleShowPrivacyPolicy');
            if (!this.$store.state.showUserSignup && !this.$store.state.showUserUpdate && !this.$store.state.showLogin) {
                if (this.getAdsSelectedFilterCategory === category) {
                    return;
                }

                if (!this.$store.state.adsSelectedFilterCategory) this.$store.commit('toggleShowAdsFilterPanel');
                this.$store.commit('setAdsSelectedFilterCategory', category);
                this.filters.category = category;
                try {
                    //console.log("Categoria: ", this.filters.category);
                    const filters = this.filters;
                    const page = 1;
                    await this.loadAdsByFilter({ filters, page });
                } catch (error) {
                    console.log(error);
                }
            }
            if (this.isSmallPage) this.$store.commit('touggleShowCategories');
        },
        async showFavourites() {
            const result = await this.$store.dispatch('loadAdsFavourites', this.user._id);
            if (result) {
                this.$store.commit('toggleShowFavouritesAds');
                if (this.isSmallPage) this.$store.commit('touggleShowCategories');
            }
        },
        adsManager() {
            if (this.getIsBacker) {
                this.$store.commit('hideAllContentPanel');
                this.$store.commit('toggleShowAdsManager');
                this.$store.commit('toggleShowListManager');
            } else {
                toast.warning('Somente disponível para os apoiadores no APOIA.se.\nTorne-se um apoiador!', { timeout: 6000 });
            }
            if (this.isSmallPage) this.$store.commit('touggleShowCategories');
        },
        isSmallPage() {
			return this.$store.state.smallPage;
		}
    },
    data() {
        return {
            filters: {
                category: '',
                state: '',
                city: '',
                title: '',
                description: '',
                min: 0,
                max: 0
            },
            categories: [
                { id: 1, name: "Veleiros" },
                { id: 2, name: "Velas, acessórios e peças" },
                { id: 3, name: "Eletrônicos náuticos" },
                { id: 4, name: "Motores" },
                { id: 5, name: "Botes, caiaques e pranchas" },
                { id: 6, name: "Vagas e poitas" },
                { id: 7, name: "Carretas" },
                { id: 8, name: "Serviços" },
                { id: 9, name: "Alimentação" },
                { id: 10, name: "Hospedagem" },
                { id: 11, name: "Cursos" },
                { id: 12, name: "Eventos" }
            ]
        }
    }
})
</script>

<style scoped>
.title {
    color: var(--branco-cv);
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

.category {
    display: inline;
    align-items: center;
}

.category__itens {
    display: flex;
    width: 212px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 0px 16px 0px;
    padding: 12px 0px 10px 0px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.category__itens:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.category__itens-alt {
    display: flex;
    width: 212px;
    align-items: center;
    justify-content: center;
    background-color: var(--cinza-disable-cv);
    margin: 16px 0px 16px 0px;
    padding: 12px 0px 10px 0px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__adsManager {
    display: flex;
    width: 212px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 0px 16px 0px;
    padding: 12px 0px 10px 0px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__adsManager:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__favourites {
    display: flex;
    width: 212px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 0px 16px 0px;
    padding: 12px 0px 10px 0px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__favourites:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.text_apoiase {
    color: var(--branco-cv);
    text-align: center;
    font-size: 16px;
    padding-top: 12px;
}

.button {
    display: flex;
    justify-content: center;
    padding: 16px 0 0 0;
}

.button__apoiase {
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 12px 12px 12px 12px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}
</style>

<template>
  <div v-if="this.$store.state.userAdsQuantity > 0" class="list_adsManager">
    <p class="list_title">Seus anúncios</p>
    <p class="info__ads">
      Você pode ativar e desativar cada anúncio individualmente, clicando no botão "Desativar Anúncio / Ativar Anúncio".
    </p> 
    <p class="info__ads">Anúncios ativos expiram 1 ano após a data de criação ou última atualização/ativação.</p>
    <p class="info__ads">Anúncios inativos expiram 3 meses após a data da desativação.</p>
    <p class="info__ads">Anúncios expirados são removidos automaticamente e sem nenhum aviso.</p>
    <ul class="ads">
      <li :class="{ 'ads__itens': !smallPage, 'ads__itens__alt': smallPage }" v-for="ad in getAdsByUser" :key="ad._id">
        <div :class="{ 'pic__title': !smallPage, 'pic__title__alt': smallPage }" @click="selectAd(ad)">
          <img :class="{ 'adPic': !smallPage, 'adPic__alt': smallPage }" :src="profileImagePath(ad.photos[0])"
            alt="Foto do item">
          <div>
            <p :class="{ 'ad__title': !smallPage, 'ad__title__alt': smallPage }">
              {{ ad.title }}
            </p>
            <p :class="{ 'ad__expiryat': !smallPage, 'ad__expiryat__alt': smallPage }">
              Expira em {{ ad.expiryAt ? formatDate(ad.expiryAt) : 'N/A' }}
            </p>
          </div>
        </div>
        <div :class="{ 'actions': !smallPage, 'actions__alt': smallPage }">
          <div class="action__button">
            <p class="action__orange" v-if="ad.status == true" @click="updateAdStatus(ad._id, false)">Desativar Anúncio
            </p>

            <p class="action__green" v-if="ad.status == false" @click="updateAdStatus(ad._id, true)">Ativar Anúncio</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-else class="no_ads">
    <p>Você não tem anúncios criados no momento.</p>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

const imageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;

export default defineComponent({
  name: 'adsListManager',
  computed: {
    ...mapState(['selectedAd', 'smallPage']),
    ...mapGetters(['getAdsByUser']),
  },
  methods: {
    async updateAdStatus(adId, status) {
      const ad = { adId: adId, status: status };

      try {
        const result = await this.$store.dispatch('updateAdStatus', ad);

        if (result.data == "Status atualizado") {
          try {
            await this.loadAdsByUserId();
          } catch (error) {
            console.log(error);
            if (error.errorMessage == "Não foram encontrados anúncios para este usuário!") {
              this.$store.commit('setUserAdsQuantity', 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectAd(ad) {
      //console.log("Anúncio selecionado: ", ad);
      this.$store.dispatch('updateSelectedAd', ad);
      this.$store.commit('toggleShowAdUpdate');
      this.$store.commit('toggleShowListManager');
    },
    profileImagePath(adImage) {
      const adImageName = adImage;
      const timestamp = Date.now();
      return imageStorageLink + adImageName + '?t=' + timestamp;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    ...mapActions(['loadAdsByUserId']),
  },
  async mounted() {
    try {
      await this.loadAdsByUserId();
    } catch (error) {
      console.log(error);
      if (error.errorMessage == "Não foram encontrados anúncios para este usuário!") {
        this.$store.commit('setUserAdsQuantity', 0);
      }
    }
  },
});
</script>
  

<style>
.list_adsManager {
  max-height: 541px;
  /* Altere para a altura que você deseja */
  overflow-y: auto;
  /* Exibe barra de rolagem vertical se necessário */
}

.info__ads {
  font-size: 14px;
  padding: 0 16px 5px 16px;
  text-align: center;
  gap: 6px;
  color: var(--laranja-cv);
}

.no_ads {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding: 40px 0 40px 0;
}

.ads__itens {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 0 0;
  gap: 10px;
  background-color: var(--azul-escuro-cv);
}

.ads__itens__alt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 0 0;
  gap: 10px;
  background-color: var(--azul-escuro-cv);
}

.ads__itens:hover {
  background-color: var(--bege-cv);
  color: var(--azul-escuro-cv);
}

.pic__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pic__title__alt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adPic {
  display: flex;
  width: 150px;
  border-radius: 1%;
  border: solid;
  border-width: 3px;
  align-content: center;
}

.adPic__alt {
  display: flex;
  width: 150px;
  border-radius: 1%;
  border: solid;
  border-width: 3px;
  align-content: center;
  margin: 16px 0 0 0;
}

.ad__title {
  padding: 0 0 0 16px;
}

.ad__title__alt {
  padding: 16px 0 0 16px;
}

.ad__expiryat {
  font-size: 12px;
  padding: 5px 0 0 16px;
}

.ad__expiryat__alt {
  font-size: 12px;
  padding: 16px 0 0 16px;
}

.list_title {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 22px;
  padding: 0 0 16px 0;
}

.action__button {
  display: flex;
  font-family: Ubuntu;
  font-size: 12px;
  width: 100px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background-color: var(--branco-cv);
  margin: 0 10px 0 10px;
  padding: 2px 2px 2px 2px;
  color: var(--azul-escuro-cv);
  border-radius: 4px;
  border-style: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
}

.action__button:hover {
  background-color: var(--azul-claro-cv);
  color: var(--azul-escuro-cv);
}

.actions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
}

.actions__alt {
  display: flex;
  margin: 0 0 16px 0;
}

.action__green {
  font-weight: bold;
  color: var(--verde-cv);
  line-height: normal;
}

.action__orange {
  font-weight: bold;
  color: var(--laranja-cv);
  line-height: normal;
}
</style>
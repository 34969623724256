// store.js
import { createStore } from 'vuex';
import { useToast } from 'vue-toastification'
import Cookies from 'js-cookie';
import axios from 'axios';

const toast = useToast();

// Set environment variables
const imageServerBaseUrl = process.env.VUE_APP_IMAGE_SERVER_BASE_URL;
const loginServerBaseUrl = process.env.VUE_APP_DATA_SERVER_BASE_URL;
const dataServerBaseUrl = process.env.VUE_APP_DATA_SERVER_BASE_URL;
const cookieDomain = process.env.VUE_APP_SITE_URL;

// Create VUEX state store
const store = createStore({
  state: {
    showLogin: false,
    showUserSignup: false,
    showForgotPassword: false,
    showResetPassword: false,
    showUserUpdate: false,
    showUserDelete: false,
    showUserChangePassword: false,
    showRedefinitionInvalid: false,
    showSlider: true,
    showAdsDisplay: true,
    showMobileMenu: false,
    showCategories: true,
    showPassword: false,
    showAdsManager: false,
    showListManager: false,
    showAdInterested: false,
    showAdCreate: false,
    showAdView: false,
    showAdsFilterPanel: false,
    showAdsFilters: false,
    showFavouritesAds: false,
    showTermsAndConditions: false,
    ShowPrivacyPolicy: false,

    smallPage: false,
    user: null,
    userProfileImageName: '',
    advertiser: null,
    advertiserProfileImageName: '',
    maintainImage: true,
    userActualEmail: null,
    token: Cookies.get('user_token') || null,
    userActive: false,
    isBacker: false,
    lastContributionYearMonth: false,
    validUser: true,
    correctionsNeeded: [],
    userToUpdate: null,
    userIdReset: null,
    userTokenReset: null,

    adsList: null,
    adsListQuantity: null,
    selectedAdToView: null,
    favouritedAd: false,

    adsFilteredList: null,
    adsFilteredListQuantity: null,
    adsSelectedFilterCategory: null,
    adsTotalPages: null,
    adsTotalDocuments: null,

    adsInterestedList: null,
    adsInterestedQuantity: null,
    adsInterestedTotalPages: null,
    adsInterestedTotalDocuments: null,

    sliderPhotos: [],

    ad: null,
    ads: null,
    userAdsQuantity: null,
    adState: null,
    adCity: null,
    theStates: [],
    cities: [],
    selectedState: null,
    selectedCity: null,
    validAd: true,
    selectedAd: null,
    adToUpdate: null,

    photosKept: [],
    photosDeleted: [],
    photosAdded: [],
    adFilesDeleted: [],
    adFilesAdded: [],
  },
  getters: {
    getAdsList(state) {
      return state.adsList;
    },
    getAdsFilteredList(state) {
      return state.adsFilteredList;
    },
    getAdsByUser(state) {
      return state.ads;
    },
    getAdsInterested(state) {
      return state.adsInterestedList;
    },
    getSliderPhotos(state) {
      return state.sliderPhotos;
    },
    getSelectedAdToView(state) {
      return state.selectedAdToView;
    },
    getFavouritedAdStatus(state) {
      return state.favouritedAd;
    },
    getAdsSelectedFilterCategory(state) {
      return state.adsSelectedFilterCategory;
    },
    getIsBacker(state) {
      return state.isBacker;
    },
    getUserId(state) {
      return state.user.id;
    },
  },
  mutations: {
    // Sets component visibility
    hideAllContentPanel(state) {
      state.showLogin = false
      state.showUserSignup = false
      state.showForgotPassword = false
      state.showResetPassword = false
      state.showUserUpdate = false
      state.showUserDelete = false
      state.showUserChangePassword = false
      state.showRedefinitionInvalid = false
      state.showAdsManager = false
      state.showAdCreate = false
      state.showAdUpdate = false
      state.showListManager = false
      state.showAdInterested = false
      state.showSlider = false
      state.showAdsDisplay = false
      state.showAdView = false
      state.showAdsFilterPanel = false
      state.showTermsAndConditions = false
      state.ShowPrivacyPolicy = false
      state.adsSelectedFilterCategory = null
    },
    touggleShowAdvertiseButton(state) {
      state.showAdvertiseButton = !state.showAdvertiseButton
    },
    touggleShowLogin(state) {
      state.showLogin = !state.showLogin;
    },
    touggleShowForgotPassword(state) {
      state.showForgotPassword = !state.showForgotPassword;
    },
    touggleShowResetPassword(state) {
      state.showResetPassword = !state.showResetPassword;
    },
    touggleShowResetPasswordOn(state) {
      state.showResetPassword = true;
    },
    touggleShowUserSignup(state) {
      state.showUserSignup = !state.showUserSignup;
    },
    touggleShowUserUpdate(state) {
      state.showUserUpdate = !state.showUserUpdate;
    },
    touggleShowUserDelete(state) {
      state.showUserDelete = !state.showUserDelete;
    },
    touggleShowUserChangePassword(state) {
      state.showUserChangePassword = !state.showUserChangePassword;
    },
    touggleShowRedefinitionInvalid(state) {
      state.showRedefinitionInvalid = !state.showRedefinitionInvalid;
    },
    touggleShowSlider(state) {
      state.showSlider = !state.showSlider;
    },
    touggleShowSliderOff(state) {
      state.showSlider = false;
    },
    touggleShowMobileMenu(state) {
      state.showMobileMenu = !state.showMobileMenu;
    },
    touggleShowCategories(state) {
      state.showCategories = !state.showCategories;
    },
    toggleShowAdsManager(state) {
      state.showAdsManager = !state.showAdsManager;
    },
    toggleHideAdsManager(state) {
      state.showAdsManager = false;
    },
    toggleShowListManager(state) {
      state.showListManager = !state.showListManager;
    },
    toggleShowAdInterested(state) {
      state.showAdInterested = !state.showAdInterested;
    },
    toggleShowAdCreate(state) {
      state.showAdCreate = !state.showAdCreate;
    },
    toggleShowAdUpdate(state) {
      state.showAdUpdate = !state.showAdUpdate;
    },
    toggleShowAdsDisplay(state) {
      state.showAdsDisplay = !state.showAdsDisplay;
    },
    toggleShowAdsDisplayOff(state) {
      state.showAdsDisplay = false;
    },
    toggleShowAdView(state) {
      state.showAdView = !state.showAdView;
    },
    toggleShowTermsAndConditions(state) {
      state.showTermsAndConditions = !state.showTermsAndConditions;
    },
    toggleShowPrivacyPolicy(state) {
      state.ShowPrivacyPolicy = !state.ShowPrivacyPolicy;
    },
    toggleShowAdsFilterPanel(state) {
      state.showAdsFilterPanel = !state.showAdsFilterPanel;
    },
    toggleShowAdsFilter(state) {
      state.showAdsFilters = !state.showAdsFilters;
    },
    toggleShowAdViewOn(state) {
      state.showAdView = true;
    },
    toggleShowAdViewOff(state) {
      state.showAdView = false;
    },
    toggleShowFavouritesAds(state) {
      state.showFavouritesAds = true;
    },
    toggleHideFavouritesAds(state) {
      state.showFavouritesAds = false;
    },
    // Set Component Elements Visibility
    togglePasswordVisibility(state) {
      state.showPassword = !state.showPassword;
    },
    setAdsSelectedFilterCategory(state, category) {
      state.adsSelectedFilterCategory = category;
    },
    // Sets reactive design witdth state 
    setSmallPageOn(state) {
      state.smallPage = true;
    },
    setSmallPageOff(state) {
      state.smallPage = false;
    },
    // Sets user state to Active (logged)
    activateUser(state, responseData) {
      state.token = responseData.token;
      state.user = responseData.user;
      state.isBacker = responseData.user.isBacker;
      state.isPaidThisMonth = responseData.user.isPaidThisMonth;
      state.thisMonthPaidValue = responseData.user.thisMonthPaidValue;
      state.userActive = true;
    },
    relogUser(state, userData) {
      state.user = userData;
      state.userActive = true;
    },
    // Stores realoaded user data
    loadUserData(state, userData) {
      state.user = userData;
      state.isBacker = userData.isBacker;
      state.isPaidThisMonth = userData.isPaidThisMonth;
      state.thisMonthPaidValue = userData.thisMonthPaidValue;
    },
    // Reserves actual user mail
    setActualUserMail(state, email) {
      state.userActualEmail = email;
    },
    loadAdvertiserData(state, advertiserData) {
      state.advertiser = advertiserData;
      state.advertiserProfileImageName = advertiserData.profileImage;
    },
    // Sets user state to Inactive (logged off)
    deactivateUser(state) {
      state.user = [];
      state.userActive = false;
      state.isBacker = false;
      state.isPaidThisMonth = false;
      state.thisMonthPaidValue = 0;
    },
    // Sets user as a e-mail validated user
    setValidUser(state, status) {
      state.user.validUser = status;
    },
    setUserIdReset(state, userId) {
      state.userIdReset = userId;
    },
    setUserTokenReset(state, tokenReset) {
      state.userTokenReset = tokenReset;
    },
    // Store the logged user data
    addUser(state, user) {
      state.user = null;
      state.user = user;
    },
    updateCnpj(state, value) {
      state.user.cnpj = value;
    },
    updateCellphone(state, value) {
      state.user.cellPhone = value;
    },
    // Stores the profile user image name
    updateUserProfileImage(state, payload) {
      const imageName = payload;
      state.user.profileImage = imageName;
    },
    // Composes a temporary image user name to send to storage, when then will receive the final name.
    addFileImageName(state, payload) {
      const imageName = payload;
      state.userProfileImageName = ("userProfileImage_default" + imageName.substring(imageName.length - 4));
    },
    // Sets a state to maintain using the current user profile image
    useFiledImage(state) {
      state.maintainImage = true;
    },
    // Sets a state to let the user profile image be changed.
    changeFiledImage(state) {
      state.maintainImage = false;
    },
    // Sets a copy o user data with modifications to update on database
    setUserToUpdate(state, user) {
      state.userToUpdate = user;
    },
    // Resets corrections needed array
    resetCorrectionsNeeded(state) {
      state.validUser = true;
      state.correctionsNeeded = [];
    },
    // Validates the user data before to send for database.
    validateUser(state, userToCheck) {

      state.validUser = true;
      state.correctionsNeeded = [];

      // Validates the type of user
      if (userToCheck.type != "Velejador" &&
        userToCheck.type != "Profissional Autônomo" &&
        userToCheck.type != "Representante de Empresa") {
        state.validUser = false;
        state.correctionsNeeded.push('O tipo de usuário precisa ser selecionado.');
      }

      // Validadtes the Company name when it's necessary.
      // Regex: valida nomes de até 50 caracteres, aceitando apenas letras, espaços e alguns caracteres acentuados comuns em línguas latinas
      const regexName = /^[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ\s]{3,50}[a-zA-ZÀ-ÿ]$/

      if (userToCheck.type == "Representante de Empresa") {
        
        if (userToCheck.company != "none" &&
          userToCheck.company.length < 3 ||
          userToCheck.company.length > 30 ||
          !regexName.test(userToCheck.company)) {
            state.validUser = false;
            state.correctionsNeeded.push('O nome da empresa deve conter entre 3 a 30 caracteres e apenas letras maiúsculas e minúsculas, caracteres acentuados e espaços em branco.');
          }
        
        // Validadtes the Company CNPJ.
        // Regex: valida CNPJ
        const regexCnpj = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

        if (userToCheck.cnpj != "none" &&
          !regexCnpj.test(userToCheck.cnpj)) {
          state.validUser = false;
          state.correctionsNeeded.push('O CNPJ deve seguir o padrão: XX.XXX.XXX/0001-XX, onde X é um caracter numérico de 0 a 9.');
        }

        if (userToCheck.cnpj != "none" &&
          regexCnpj.test(userToCheck.cnpj) &&
          !isValidCNPJ(userToCheck.cnpj)) {
            state.validUser = false;
            state.correctionsNeeded.push("Precisa ser CNPJ válido!");
        }
      }

      // Validadtes the name of user
      if (userToCheck.name.length > 30 ||
        userToCheck.name.length < 3 ||
        !regexName.test(userToCheck.name)) {
        state.validUser = false;
        state.correctionsNeeded.push('O nome do usuário deve conter entre 3 a 30 caracteres e apenas letras maiúsculas e minúsculas, caracteres acentuados e espaços em branco.');
      }

      // Validates the e-mail of user
      const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

      if (!regexEmail.test(userToCheck.email)) {
        state.validUser = false;
        state.correctionsNeeded.push('O e-mail precisa ser ter um padrão válido.');
      }

      // Validates the cell phone number of user
      const regexCellphone = /^(\+\d{2})\s\((\d{2})\)\s(\d{4,5})-(\d{4})$/;

      if (!regexCellphone.test(userToCheck.cellPhone)) {
        state.validUser = false;
        state.correctionsNeeded.push('O número do celular deve ter esse padrão: +55 (21) 99876-5432');
      }

      // Validates the profile's user image name and size
      const regexuserProfileImage = /^[a-zA-Z0-9_-]{3,20}\.jpg$/

      if (userToCheck.userProfileImage != null) {
        if (!regexuserProfileImage.test(userToCheck.userProfileImage)) {
          state.validUser = false;
          state.correctionsNeeded.push('O arquivo de imagem de ser no formato JPG.');
        }
        // Validates the profile's user image size
        const MAX_FILE_SIZE = 1000000; // 1000 KB
        if (userToCheck.file && userToCheck.file.size > MAX_FILE_SIZE) {
          state.validUser = false;
          state.correctionsNeeded.push('O tamanho máximo permitido do arquivo para foto do perfil é de 1M.');
        }
      }

      // Validates if the password is equal to password's confirmation
      if (userToCheck.password !== userToCheck.passwordConfirm) {
        state.validUser = false;
        state.correctionsNeeded.push('A senha e a confirmação de senha não estão iguais.');
      }

      // Validates if password is conformed with pattern
      const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/

      if (!regexPassword.test(userToCheck.password)) {
        state.validUser = false;
        state.correctionsNeeded.push('A senha tem no mínimo 8 caracteres e incluir pelo menos um número, uma letra maiúscula, uma letra minúscula e um dos seguintes caractere especiais: !@#$%^&*');
      } //Asd@1234

      return state.validUser
    },

    // Validates updating user data before to send for database.
    validateUpdateUser(state, userToCheck) {
      state.validUser = true;
      state.correctionsNeeded = [];

      // Validates the type of user
      if (userToCheck.type != "Velejador" &&
        userToCheck.type != "Profissional Autônomo" &&
        userToCheck.type != "Representante de Empresa") {
        state.validUser = false;
        state.correctionsNeeded.push('O tipo de usuário precisa ser selecionado.');
      }

      // Validadtes the Company name when it's necessary.
      // Regex: valida nomes de até 50 caracteres, aceitando apenas letras, espaços e alguns caracteres acentuados comuns em línguas latinas
      const regexName = /^[a-zA-ZÀ-ÿ][a-zA-ZÀ-ÿ\s]{2,50}[a-zA-ZÀ-ÿ]$/

      if (userToCheck.type == "Representante de Empresa") {

        if (userToCheck.company != "none" &&
          userToCheck.company.length < 3 ||
          userToCheck.company.length > 50 ||
          !regexName.test(userToCheck.company)) {
          state.validUser = false;
          state.correctionsNeeded.push('O nome da empresa deve conter apenas letras maiúsculas e minúsculas, caracteres acentuados e espaços em branco.');
        }

        // Validadtes the Company CNPJ.
        // Regex: valida CNPJ
        const regexCnpj = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

        if (userToCheck.cnpj != "none" &&
          !regexCnpj.test(userToCheck.cnpj)) {
          state.validUser = false;
          state.correctionsNeeded.push('O CNPJ deve seguir o padrão: XX.XXX.XXX/0001-XX, onde X é um caracter numérico de 0 a 9.');
        }

        if (userToCheck.cnpj != "none" &&
          regexCnpj.test(userToCheck.cnpj) &&
          !isValidCNPJ(userToCheck.cnpj)) {
            state.validUser = false;
            state.correctionsNeeded.push("Precisa ser CNPJ válido!");
        }
      }

      // Validadtes the name of user
      if (userToCheck.name.length > 30 ||
        userToCheck.name.length < 3 ||
        !regexName.test(userToCheck.name)) {
        state.validUser = false;
        state.correctionsNeeded.push('O nome do usuário deve conter apenas letras maiúsculas e minúsculas, caracteres acentuados e espaços em branco.');
      }

      // Validates the e-mail of user
      const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

      if (!regexEmail.test(userToCheck.email)) {
        state.validUser = false;
        state.correctionsNeeded.push('O e-mail precisa ser ter um padrão válido.');
      } else {
        if (userToCheck.email != state.userActualEmail) {
          state.user.verified = false;
        }
      }

      // Validates the cell phone number of user
      const regexCellphone = /^(\+\d{2})\s\((\d{2})\)\s(\d{4,5})-(\d{4})$/;

      if (!regexCellphone.test(userToCheck.cellPhone)) {
        state.validUser = false;
        state.correctionsNeeded.push('O número do celular deve ter esse padrão: +55 (21) 99876-5432');
      }

      // Validates the profile's user image name and size
      const regexuserProfileImage = /^[a-zA-Z0-9_-]{3,20}\.jpg$/

      if (userToCheck.userProfileImage != null) {
        if (!regexuserProfileImage.test(userToCheck.userProfileImage)) {
          state.validUser = false;
          state.correctionsNeeded.push('');
        }
        // Valida tamanho da imagem do perfil
        const MAX_FILE_SIZE = 1000000; // 1000 KB
        if (userToCheck.file && userToCheck.file.size > MAX_FILE_SIZE) {
          state.validUser = false;
          state.correctionsNeeded.push('O tamanho máximo permitido do arquivo para foto do perfil é de 1MB, no formato JPG.');
        }
      }
      return state.validUser
    },
    SET_STATES(state, theStates) {
      state.theStates = theStates;
    },
    SET_CITIES(state, cities) {
      state.cities = cities;
    },
    SET_SELECTED_STATE(state, theState) {
      state.selectedState = theState;
    },
    SET_SELECTED_CITY(state, city) {
      state.selectedCity = city;
    },
    // Store the quantity of ads readed for main display list
    setAdsListQuantity(state, quatity) {
      state.adsListQuantity = quatity;
    },
    // Store the quantity of ads readed for filtered display list
    setAdsFilteredListQuantity(state, quatity) {
      state.adsFilteredListQuantity = quatity;
    },
    //Load ads data readed for main display list
    loadAdsListData(state, adsData) {
      state.adsList = adsData;
      state.adsListQuantity = adsData.length;
    },
    //Load ads data readed for main display list
    loadAdsFilteredListData(state, adsData) {
      state.adsList = adsData;
      state.adsListQuantity = adsData.length;
    },
    loadAdsInterestedListData(state, adsData) {
      state.adsInterestedList = adsData;
      state.adsInterestedQuantity = adsData.length;
    },
    //Load ads data readed for filtered ads display list
    loadPhotoSlider(state, adsData) {
      state.sliderPhotos = [];
      adsData.forEach(function (ad, index) {
        state.sliderPhotos[index] = { name: ad.photos[0], caption: ad.title, priority: ad.displayPriority, value: ad.value };
        //state.sliderPhotos = sortPhotos(state.sliderPhotos);
      })
    },
    setTotalPages(state, totalPages) {
      state.adsTotalPages = totalPages;
    },
    setTotalDocuments(state, totalDocuments) {
      state.adsTotalDocuments = totalDocuments;
    },
    setInterestedTotalPages(state, totalPages) {
      state.adsInterestedTotalPages = totalPages;
    },
    setInterestedTotalDocuments(state, totalDocuments) {
      state.adsInterestedTotalDocuments = totalDocuments;
    },
    // Store the ad data
    addAd(state, ad) {
      state.ad = null;
      state.ad = ad;
    },
    // Store user Ads quantity
    setUserAdsQuantity(state, quatity) {
      state.userAdsQuantity = quatity;
    },
    // Store Ads Interested quantity
    setAdsInterestedQuantity(state, quatity) {
      state.adsInterestedQuantity = quatity;
    },
    loadAdsData(state, adsData) {
      state.ads = adsData;
      state.userAdsQuantity = adsData.length;
    },
    updateAdImagesNames(state, payload) {
      const imageName = payload;
      for (let i = 0; i < 6; i++) {
        state.ad.photos[i] = imageName[i];
      }
    },
    UPDATE_SELECTED_AD: (state, ad) => {
      state.selectedAd = ad;
      state.photosKept = ad.photos;
    },
    SELECT_AD_TO_VIEW: (state, ad) => {
      state.selectedAdToView = ad;
    },
    setFavouriteAd: (state, status) => {
      state.favouritedAd = status;
    },
    cleanArrays(state) {
      state.photosDeleted = [];
      state.photosAdded = [];
      state.adFilesDeleted = [];
      state.adFilesAdded = [];
    },
    setAdToUpdate(state, ad) {
      state.adToUpdate = ad;
    },
    setPhotosDeleted(state, deletedPhoto) {
      state.photosDeleted.push(deletedPhoto);
    },
    setPhotosAdded(state, newPhoto) {
      state.photosAdded.push(newPhoto);
    },
    setAdFilesAdded(state, newFile) {
      state.adFilesAdded.push(newFile);
    },
    validateAd(state, adToCheck) {

      state.validAd = true;
      state.correctionsNeeded = [];

      // Validates the category
      if (adToCheck.category == null) {
        state.validAd = false;
        state.correctionsNeeded.push('A categoria do item anunciado precisa ser selecionada.');
      }

      // Validates the state
      if (adToCheck.state == null) {
        state.validAd = false;
        state.correctionsNeeded.push('O estado onde se encontra o item anunciado precisa ser selecionado.');
      }

      // Validates the city
      if (adToCheck.city == null) {
        state.validAd = false;
        state.correctionsNeeded.push('A cidade onde se encontra o item anunciado precisa ser selecionada.');
      }

      // Validadtes the title.
      // Regex: valida titulo de até 50 caracteres, aceitando apenas letras, espaços e alguns caracteres acentuados comuns em línguas latinas
      const regexTitle = /^[a-zà-úA-ZÀ-Ú0-9\s.,°ºª-]{3,40}$/

      if (adToCheck.title.length < 3 ||
        adToCheck.title.length > 40 ||
        !regexTitle.test(adToCheck.title)) {
        state.validAd = false;
        state.correctionsNeeded.push('O título deve conter entre 3 a 40 caracteres e apenas letras maiúsculas e minúsculas, caracteres acentuados, números e espaços em branco.');
      }

      const regexDescription = /^[a-zA-Z0-9À-ÿ ,’'`°ºª;:.!%$*?@&'"/\\()\\[\]\r\n-]+$/;

      // Valida a descrição
      if (adToCheck.description.length > 5000 ||
          adToCheck.description.length < 20 ||
          !regexDescription.test(adToCheck.description)) {
        state.validAd = false;

        let errorMsg = 'A descrição deve conter entre 20 a 5000 caracteres. Aceita somente letras, números, espaços e alguns caracteres especiais';

        // Identifica caracteres inválidos
        const invalidChars = findInvalidCharacters(adToCheck.description, regexDescription);
        if (invalidChars.length > 0) {
          errorMsg += `. Caracteres inválidos encontrados: ${invalidChars.join(', ')}`;
        }

        state.correctionsNeeded.push(errorMsg);
      }

      // Validates the value
      if (adToCheck.value < 0) {
        state.validAd = false;
        state.correctionsNeeded.push('O valor precisa ser um número positivo.');
      }

      if (adToCheck.photos.length > 0) {

        adToCheck.imageFiles.forEach(function (file, i) {
          // Validates the ad image size
          const MAX_FILE_SIZE = 1000000; // 1000 KB
          if (file && file.size > MAX_FILE_SIZE) {
            state.validAd = false;
            state.correctionsNeeded.push(`O tamanho máximo permitido para arquivo de foto é de 1MB. Foto ${i + 1}`);
          }
        });
      } else {
        state.validAd = false;
        state.correctionsNeeded.push(`Ao menos uma foto precisa ser adicionada.`);
      }

      return state.validAd
    },
    generatePhotosUpdateValidation(state) {
      let photosUpdateValidation = [];

      // Concatenar fotos mantidas e adicionadas
      photosUpdateValidation = [...state.photosKept, ...state.photosAdded];

      // Remover fotos que foram deletadas
      photosUpdateValidation = photosUpdateValidation.filter(photo => !state.photosDeleted.includes(photo));

      state.photosUpdateValidation = photosUpdateValidation;
    }
  },
  actions: {
    // Check if user email is already subscribed.
    async isUserEmailSubscribed(_, email) {
      try {
        const loginServerUrl = `${loginServerBaseUrl}/users/email/` + email;
        const customAxiosInstance_load = getAxiosInstance();
        const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });

        if (response.data.isSubscribed == true) {
          toast.error("Já existe no ClassiVela um perfil cadastrado com o endereço de e-mail informado!");
          return true;
        } else {
          return false;
        }

      } catch (error) {
        console.log(error);
      }
    },
    // Creates a new user
    async createUser({ commit }, user) {
      try {
        // Use the custom Axios instance.
        const customAxiosInstance_create = getAxiosInstance();
        const response = await customAxiosInstance_create.post(`${dataServerBaseUrl}/users`, user, { withCredentials: true });
        commit('addUser', response.data.newUser);
        // Armazene o token no cookie
        Cookies.set('user_token', response.data.token, { expires: 7, path: '/', domain: cookieDomain });

        return response.data.newUser;

      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema durante o processo de cadastro. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Updates the current user data
    async updateUser({ state }) {
      const userToUpdate = state.userToUpdate;
      const token = Cookies.get('user_token');

      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_update = getAxiosInstance();
        customAxiosInstance_update.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_update.put(`${dataServerBaseUrl}/users/${userToUpdate._id}/updateUser`, userToUpdate, { withCredentials: true });

        return response.data;

      } catch (error) {
        //console.log(error);
        toast.error('Ocorreu algum problema durante o processo de atualização do cadastro. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Changes the current users password
    async changeUserPassword({ state }, passwordToChange) {
      const id = state.user._id;
      const token = Cookies.get('user_token');

      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_updatePassword = getAxiosInstance();
        customAxiosInstance_updatePassword.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_updatePassword.post(`${dataServerBaseUrl}/users/${id}/updateUserPassword`, passwordToChange, { withCredentials: true });

        return response.data;

      } catch (error) {
        //console.log(error);
        if (error.response.data.message === "Usuário não encontrado!\nVerifique se o e-mail está correto.") {
          toast.error(error.response.data.message);
        } else if (error.response.data.message === "E-mail ainda não validado!") {
          toast.warning(error.response.data.message);
        } else if (error.response.data.message === "Senha inválida!") {
          toast.error("Senha atual inválida!");
        }
      }
    },
    async forgotPassword(_, userEmail) {
      try {
        const customAxiosInstance_create = getAxiosInstance();
        const response = await customAxiosInstance_create.post(`${dataServerBaseUrl}/users/forgotPassword/`, userEmail, { withCredentials: true });

        toast.warning('Você receberá um e-mail com instruções para redefinir a sua senha.', { timeout: 6000 });
        return response;

      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error('Não há um usuário cadastrado com esse endereço de e-mail!', { timeout: 6000 });
        } else { 
          console.log(error);
          toast.error('Ocorreu algum problema durante o processo de cadastro. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
        }
      }
    },

    async newUserPassword(_, payload) {

      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_updatePassword = getAxiosInstance();
        const response = await customAxiosInstance_updatePassword.post(`${dataServerBaseUrl}/users/redefinePassword`, payload, { withCredentials: true });

        return response.data;

      } catch (error) {
        //console.log(error);
        if (error.response.data.message === "Usuário não encontrado!\nVerifique se o e-mail está correto.") {
          toast.error(error.response.data.message);
        } else if (error.response.data.message === "A autenticação da redefinição inválida! Solicite uma nova redefinição senha na página de login.") {
          toast.warning(error.response.data.message);
        }
      }
    },
    // Uploads the profile's user image to storage and registrates the name on database
    async uploadImage({ commit }, payload) {
      const { userId, image, token } = payload;

      try {
        const formData = new FormData();
        formData.append('userProfileImage_default', image);

        const imageServerUrl = `${imageServerBaseUrl}/users/${userId}/upload`;

        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_upload = getAxiosInstance();

        customAxiosInstance_upload.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_upload.post(imageServerUrl, formData, { withCredentials: true });

        commit('updateUserProfileImage', response.data.userProfileImageName);

        //Cookies.remove('user_token', { path: '' });

        return;

      } catch (error) {
        //console.log(error);
        toast.error('Ocorreu algum problema durante o processo de cadastro. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Sends the validation e-mail when the user is created or e-mail address is updated.
    async sendValidationEmail({ state }) {
      const recipientUser = state.user._id;
      const token = Cookies.get('user_token');
      const recipientMail = state.user.email;

      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_mail = getAxiosInstance();
        customAxiosInstance_mail.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_mail.put(`${dataServerBaseUrl}/users/${recipientUser}/sendValidationEmail`, recipientMail, { withCredentials: true });

        return response;

      } catch (error) {
        //console.log(error);
        toast.error('Ocorreu algum problema durante o processo de atualização do cadastro. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Deletes data of the current user
    async deleteUser({ state }, userToDelete) {
      const id = state.user._id;
      const token = Cookies.get('user_token');

      try {

        const loginServerUrl = `${loginServerBaseUrl}/users/login`;
        await axios.post(loginServerUrl, userToDelete, { withCredentials: true });

        try {
          // Use the custom Axios instance with the correct headers.
          const customAxiosInstance_delete = getAxiosInstance();
          customAxiosInstance_delete.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await customAxiosInstance_delete.delete(`${dataServerBaseUrl}/users/${id}/deleteUser`, { withCredentials: true });

          return response.data;

        } catch (error) {
          //console.log(error);
          toast.error('Ocorreu algum problema durante o processo de exclusão do perfil. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
        }

      } catch (error) {
        if (error.response.data.message === "Usuário não encontrado!\nVerifique se o e-mail está correto.") {
          toast.error(error.response.data.message);
        } else if (error.response.data.message === "E-mail ainda não validado!") {
          toast.warning(error.response.data.message);
        } else if (error.response.data.message === "Senha inválida!") {
          toast.error(error.response.data.message);
        }
      }
    },
    // Log in the user
    async loginUser({ commit }, userLogin) {
      try {
        const loginServerUrl = `${loginServerBaseUrl}/users/login`;
        const response = await axios.post(loginServerUrl, userLogin, { withCredentials: true });

        // Stores the token cookie on the navigator
        Cookies.set('user_token', response.data.token, { expires: 7, path: '/', domain: cookieDomain });

        const userName = response.data.user.name.split(" ")[0];
        commit('activateUser', response.data);
        toast.success(`Bem-vindo, ${userName}!`);

      } catch (error) {
        //console.log(error);
        if (error.response.data.message === "Usuário não encontrado!\nVerifique se o e-mail está correto.") {
          toast.error(error.response.data.message);
        } else if (error.response.data.message === "E-mail ainda não validado!") {
          toast.warning(error.response.data.message);
        } else if (error.response.data.message === "Senha inválida!") {
          toast.error(error.response.data.message);
        }
      }
    },
    // Loads usar data from database to update local data before leting user update it.
    async loadUser({ commit }) {
      if (this.state.userActive) {
        const token = Cookies.get('user_token');
        try {
          const loginServerUrl = `${loginServerBaseUrl}/users/` + this.state.user._id;
          const customAxiosInstance_load = getAxiosInstance();
          customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });

          commit('useFiledImage');
          commit('loadUserData', response.data);
          commit('setActualUserMail', response.data.email);

        } catch (error) {
          //console.log(error);
          if (error.response.data.message == "Usuário não encontrado!\nVerifique se o e-mail está correto.") {
            toast.error(error.response.data.message);
          }
        }
      }
    },
    async loadAdvertiser({ commit }, advertiser_id) {
        const token = Cookies.get('user_token');
        try {
          const loginServerUrl = `${loginServerBaseUrl}/users/` + advertiser_id;
          const customAxiosInstance_load = getAxiosInstance();
          customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });
          
          commit('loadAdvertiserData', response.data);
          return response.data;

        } catch (error) {
          console.log(error);
        }
    },
    async getUserRate(_, userAdsId) {
      if (this.state.userActive) {
        const token = Cookies.get('user_token');
        try {
          const loginServerUrl = `${loginServerBaseUrl}/users/getUserRate/` + userAdsId;
          const customAxiosInstance_getUserRate = getAxiosInstance();
          customAxiosInstance_getUserRate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await customAxiosInstance_getUserRate.get(loginServerUrl, { withCredentials: true });

          return response.data;

        } catch (error) {
          //console.log(error);
          if (error.response.data.message == "Erro ao buscar classificação do anunciante.") {
            toast.error(error.response.data.message);
          }
        }
      }
    },
    async getGivedUserRate(_, userId_userAdsId) {
      if (this.state.userActive) {
        const token = Cookies.get('user_token');
        try {
          const loginServerUrl = `${loginServerBaseUrl}/users/getGivedUserRate/` + userId_userAdsId;
          const customAxiosInstance_getGivedUserRate = getAxiosInstance();
          customAxiosInstance_getGivedUserRate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const response = await customAxiosInstance_getGivedUserRate.get(loginServerUrl, { withCredentials: true });

          return response.data;

        } catch (error) {
          //console.log(error);
          if (error.response.data.message == "Erro ao buscar classificação dada ao anunciante.") {
            toast.error(error.response.data.message);
          }
        }
      }
    },
    async setUserRate(_, userAdsId) {
      const token = Cookies.get('user_token');
      try {
        const loginServerUrl = `${loginServerBaseUrl}/users/setUserRate`;
        const customAxiosInstance_setUserRate = getAxiosInstance();
        customAxiosInstance_setUserRate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await customAxiosInstance_setUserRate.post(loginServerUrl, userAdsId, { withCredentials: true });
      } catch (error) {
        //console.log(error);
        if (error.response.data.message == "Erro ao tentar registrar a classificação para anunciante.") {
          toast.error(error.response.data.message);
        }
      }
    },
    // Load user data to mantain user loged when the aplication page is refreshed
    async loadUserAndActivate({ commit }, token) {
      try {
        if (token) {
          const loginServerUrl = `${loginServerBaseUrl}/users/auth`;
          const response = await axios.get(loginServerUrl, {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true
          });

          const userData = response.data;
          commit('loadUserData', response.data);
          const profileImage = userData.profileImage;
          commit('updateUserProfileImage', profileImage);
          commit('relogUser', userData);
        }
      } catch (error) {
        if (token && error.response.data.message == "Failed to authenticate token.") {
          toast.warning('Faça login novamente para renovar a sessão de acesso ao site.', { timeout: 6000 });
        } else {
          console.log(error);
        }
      }
    },
    // Log out the user
    async logoutUser({ commit }) {

      const userName = this.state.user.name.split(" ")[0]; // Get the first name

      toast.success(`Até breve, ${userName}!`);

      Cookies.remove('user_token', { path: '' }); // Removes token cookie from the navigator
      commit('deactivateUser');
    },
    async loadStates({ commit }) {
      try {
        const token = Cookies.get('user_token');
        const customAxiosInstance_states = getAxiosInstance();
        customAxiosInstance_states.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const states = await customAxiosInstance_states.get(`${dataServerBaseUrl}/states`, { withCredentials: true });

        commit('SET_STATES', states.data);
      } catch (error) {
        console.log(error);
      }
    },
    async loadCities({ commit }, theState) {
      if (theState != null) {
        try {
          const token = Cookies.get('user_token');
          const customAxiosInstance_cities = getAxiosInstance();
          customAxiosInstance_cities.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const cities = await customAxiosInstance_cities.get(`${dataServerBaseUrl}/cities/` + theState.state_acronym, { withCredentials: true });

          commit('SET_CITIES', cities.data);
          commit('SET_SELECTED_STATE', theState);
        } catch (error) {
          console.log(error);
        }
      }
    },
    selectCity({ commit }, city) {
      commit('SET_SELECTED_CITY', city);
    },

    // Creates a new ad
    async addAd({ commit }, payload) {
      const { ad, imageFiles } = payload;
      const token = Cookies.get('user_token');
      const formData = new FormData();
      try {
        // Append ad data
        for (let key in ad) {
          formData.append(key, ad[key]);
        }
        // Append imageFiles as individual files
        imageFiles.forEach(function (image) {
          formData.append('imageFiles', image);
        })

        // Use the custom Axios instance.
        const customAxiosInstance_adAdd = getAxiosInstance();
        customAxiosInstance_adAdd.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        customAxiosInstance_adAdd.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const response = await customAxiosInstance_adAdd.post(`${dataServerBaseUrl}/ads`, formData, { withCredentials: true });

        commit('addAd', response.data);

        return response.data;

      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema durante o processo de criação do anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    updateSelectedAd: ({ commit }, ad) => {
      commit('UPDATE_SELECTED_AD', ad);
    },
    selectAdToView: ({ commit }, ad) => {
      commit('SELECT_AD_TO_VIEW', ad);
    },
    generatePhotosUpdateValidation(context) {
      context.commit('generatePhotosUpdateValidation');
    },
    // Updates the current ad data
    async updateAd({ state }, ad) {
      const filesToDelete = state.photosDeleted;
      const imageFiles = state.adFilesAdded;
      const token = Cookies.get('user_token');
      const formData = new FormData();
      try {
        // Append ad data as a string
        formData.append("ad", JSON.stringify(ad));

        // Append filesToDelete as a string
        formData.append("filesToDelete", JSON.stringify(filesToDelete));

        // Append imageFiles as individual files
        imageFiles.forEach(function (image) {
          formData.append('imageFiles', image);
        })

        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_update = getAxiosInstance();
        customAxiosInstance_update.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        customAxiosInstance_update.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        const response = await customAxiosInstance_update.post(`${dataServerBaseUrl}/ads/${ad._id}/update`, formData, { withCredentials: true });

        return response.data.adUpdated;

      } catch (error) {
        console.log(error);
        toast.error('Erro dados! Ocorreu algum problema durante o processo de atualização do anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    async uploadAdImage({ commit }, payload) {
      const { adId, images, numberOfPhotos, token } = payload;
      try {
        const formData = new FormData();

        images.forEach(function (image) {
          formData.append('adImages', image);
        })

        formData.append('numberOfPhotos', numberOfPhotos);
        const imageServerUrl = `${imageServerBaseUrl}/ads/${adId}/upload`;

        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_upload = getAxiosInstance();
        customAxiosInstance_upload.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_upload.post(imageServerUrl, formData, { withCredentials: true });

        commit('updateAdImagesNames', response.data.adImageNames);

        return;

      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema durante no processamento das fotos do anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Deletes data of the current user
    async deleteAd({ state }) {
      const adId = state.selectedAd._id;

      const token = Cookies.get('user_token');

      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_delete = getAxiosInstance();
        customAxiosInstance_delete.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_delete.delete(`${dataServerBaseUrl}/ads/${adId}`, { withCredentials: true });

        return response.data;

      } catch (error) {
        //console.log(error);
        toast.error('Ocorreu algum problema durante o processo de exclusão do anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    async updateAdStatus(_, payload) {
      const token = Cookies.get('user_token');
      try {
        // Use the custom Axios instance.
        const customAxiosInstance_updateAdStatus = getAxiosInstance();
        customAxiosInstance_updateAdStatus.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const result = await customAxiosInstance_updateAdStatus.post(`${dataServerBaseUrl}/ads/updateAdStatus`, payload, { withCredentials: true });
        
        return result;
        
      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema ao atualizar status do anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    async adClickCount(_, click) {
      try {
        // Use the custom Axios instance.
        const customAxiosInstance_updateAdStatus = getAxiosInstance();
        await customAxiosInstance_updateAdStatus.post(`${dataServerBaseUrl}/ads/adClickCount`, click, { withCredentials: true });

      } catch (error) {
        console.log(error);
      }
    },
    async checkFavouriteAd({ commit }, ad) {
      const token = Cookies.get('user_token');
      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_checkFavouriteAd = getAxiosInstance();
        customAxiosInstance_checkFavouriteAd.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const result = await customAxiosInstance_checkFavouriteAd.get(`${dataServerBaseUrl}/ads/checkFavouriteAd/${ad}`, { withCredentials: true });
        if (result.data.message == "favourited") {
          commit('setFavouriteAd', true);
          return true;
        } else if (result.data.message == "unfavourited") {
          commit('setFavouriteAd', false);
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Loads ads data from database to update local data before leting user update it.
    async loadAdsByUserId({ commit }) {
      const token = Cookies.get('user_token');
      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/byUser/` + this.state.user._id;
        const customAxiosInstance_load = getAxiosInstance();
        customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });
        commit('loadAdsData', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    // Loads a specific ad data from database.
    async loadAdById(_, adId) {
      //const token = Cookies.get('user_token');
      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/byId/` + adId;
        const customAxiosInstance_load = getAxiosInstance();
        const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });
        const adFounded = response.data;
        return adFounded;
      } catch (error) {
        toast.error('Anúncio não encontrado!', { timeout: 6000 });
        console.log(error);
      }
    },
    // Loads ads data from database to update local data for filtered ads display list.
    async loadAdsByFilter({ commit }, payload) {
      //const token = Cookies.get('user_token');

      //console.log("filters: ", payload.filters);
      //console.log("page: ", payload.page);

      const filters = payload.filters;
      const page = payload.page;

      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/filter`;
        const customAxiosInstance_load = getAxiosInstance();
        //customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_load.get(loginServerUrl, { params: { filters, page } }, { withCredentials: true });

        //console.log("response.data: ", response.data);

        commit('loadPhotoSlider', response.data.content);
        commit('loadAdsFilteredListData', response.data.content);
        commit('setTotalPages', response.data.totalPages);

      } catch (error) {
        console.log(error);
      }
    },
    async loadAdsFavourites({commit}, userId) {
      const token = Cookies.get('user_token');
      const page = 1;
      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/favouriteAds/${userId}`;
        const customAxiosInstance_load_favouriteAds = getAxiosInstance();
        customAxiosInstance_load_favouriteAds.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_load_favouriteAds.get(loginServerUrl, { params: { userId, page } }, { withCredentials: true });
        //console.log("response.data: ", response.data.content);

        if (response.data.content) {
          commit('loadPhotoSlider', response.data.content);
          commit('loadAdsFilteredListData', response.data.content);
          commit('setTotalPages', response.data.totalPages);
          return true;
        } else {
          toast.warning('Você não tem favoritos cadastrados!', { timeout: 6000 });
          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Loads data abou users interested in Ads
    async loadInterestedInAds({ commit }) {
      const token = Cookies.get('user_token');
      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/interestedInAds/` + this.state.user._id;
        const customAxiosInstance_load = getAxiosInstance();
        customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_load.get(loginServerUrl, { withCredentials: true });

        if (response.data.content) {
          commit('loadAdsInterestedListData', response.data.content);
          commit('setInterestedTotalPages', response.data.totalPages);
        } else {
          commit('loadAdsInterestedListData', []);
          commit('setInterestedTotalPages', 0);
        }

      } catch (error) {
        console.log(error);
      }
    },
    async updateInterestedStatus(_, payload) {

      const token = Cookies.get('user_token');
       try {
        // Use the custom Axios instance.
        const customAxiosInstance_updateInterestedStatus = getAxiosInstance();
        customAxiosInstance_updateInterestedStatus.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const result = await customAxiosInstance_updateInterestedStatus.post(`${dataServerBaseUrl}/ads/updateInterestedStatus`, payload, { withCredentials: true });
        
        return result;
        
      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema ao atualizar dados no anúncio.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Loads data abou users interested in Ads
    async getInterestedStatus(_, payload) {
      const token = Cookies.get('user_token');
      try {
        const loginServerUrl = `${loginServerBaseUrl}/ads/getInterestedStatus/`;
        const customAxiosInstance_load = getAxiosInstance();
        customAxiosInstance_load.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_load.post(loginServerUrl, payload, { withCredentials: true });
        return response.data.status;

      } catch (error) {
        console.log(error);
      }
    },
    // Sends user contact data to advertiser
    async sendUserContact(_, contact) {
      const token = Cookies.get('user_token');
      
      try {
        // Use the custom Axios instance.
        const customAxiosInstance_sendUserContact = getAxiosInstance();
        customAxiosInstance_sendUserContact.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_sendUserContact.post(`${dataServerBaseUrl}/ads/contactUser`, contact, { withCredentials: true });

        if (response && response.data && response.data.message === "Contato do usuário enviado!") {
          toast.warning('O anunciante receberá um e-mail avisando sobre seu interesse no anúncio. Aguarde que ele faça contato com você.', { timeout: 6000 });
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Ocorreu algum problema durante o processo de envio de aviso ao anunciante. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Sends advertiser contact data to user interested in the ad
    async sendAdvertiserContact(_, contact) {
      const token = Cookies.get('user_token');

      try {
        // Use the custom Axios instance.
        const customAxiosInstance_sendAdvertiserContact = getAxiosInstance();
        customAxiosInstance_sendAdvertiserContact.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_sendAdvertiserContact.post(`${dataServerBaseUrl}/ads/contactAdvertiser`, contact, { withCredentials: true });

        if (response && response.data && response.data.message === "Contato do anunciante enviado!") {
          toast.warning('O usuário interessado no anúncio receberá um e-mail com os seu nome, e-mail e telefone para contato. Aguarde que ele faça contato com você.', { timeout: 6000 });
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Ocorreu algum problema durante o processo de envio de aviso ao interessado. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    // Sends wait advertiser contact message to user interested in the ad
    async sendWaitAdvertiserContact(_, payload) {
      const token = Cookies.get('user_token');
      
      try {
        // Use the custom Axios instance.
        const customAxiosInstance_sendWaitAdvertiserContact = getAxiosInstance();
        customAxiosInstance_sendWaitAdvertiserContact.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await customAxiosInstance_sendWaitAdvertiserContact.post(`${dataServerBaseUrl}/ads/waitAdvertiserContact`, payload, { withCredentials: true });

        if (response && response.data && response.data.message === "Mensagem de aguarde o contado enviada!") {
          toast.warning('O usuário interessado no anúncio receberá um e-mail solicitando que aguarde o seu contato.', { timeout: 6000 });
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Ocorreu algum problema durante o processo de envio de aviso ao interessado. \nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte. \nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    async setAddToFavourites({ commit }, adFavourited) {
      const token = Cookies.get('user_token');
       try {
        // Use the custom Axios instance.
        const customAxiosInstance_addAdFavourite = getAxiosInstance();
        customAxiosInstance_addAdFavourite.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const result = await customAxiosInstance_addAdFavourite.post(`${dataServerBaseUrl}/ads/favouriteAd`, adFavourited, { withCredentials: true });
        if (result.data.message == "favourited") {
          commit('setFavouriteAd', true);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        toast.error('Ocorreu algum problema ao adicionar o anúncio aos seu favoritos.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    },
    async removeAddFromFavourites({ commit }, adUnFavourited) {
      const token = Cookies.get('user_token');
      try {
        // Use the custom Axios instance with the correct headers.
        const customAxiosInstance_removeAdFavourite = getAxiosInstance();
        customAxiosInstance_removeAdFavourite.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const result = await customAxiosInstance_removeAdFavourite.delete(`${dataServerBaseUrl}/ads/unfavouriteAd/${adUnFavourited}`, { withCredentials: true });

        if (result.data.message == "unfavourited") {
          commit('setFavouriteAd', false);
          return false;
        } else {
          return true;
        }
      } catch (error) {
        //console.log(error);
        toast.error('Ocorreu algum problema ao remover o anúncio dos seu favoritos.\nO erro foi registrado nos logs do sistema para que seja revisado pelo suporte.\nTente novamente em outro momento.', { timeout: 6000 });
      }
    }
  },
  modules: {
  },
});

// Generates a Axios instance to use.
function getAxiosInstance() {
  const instance = axios.create();
  instance.defaults.headers.common['Authorization'] = axios.defaults.headers.common['Authorization'];
  return instance;
}

// Validação de CNPJ

function isValidCNPJ(cnpj) {
  if (cnpj === null || typeof cnpj === "undefined") return false;

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" || 
      cnpj === "11111111111111" || 
      cnpj === "22222222222222" || 
      cnpj === "33333333333333" || 
      cnpj === "44444444444444" || 
      cnpj === "55555555555555" || 
      cnpj === "66666666666666" || 
      cnpj === "77777777777777" || 
      cnpj === "88888888888888" || 
      cnpj === "99999999999999") {
    return false;
  }

  // Valida os dígitos de verificação
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

function findInvalidCharacters(str, regex) {
  const invalidChars = [];
  for (let i = 0; i < str.length; i++) {
    if (!regex.test(str[i])) {
      invalidChars.push(str[i]);
    }
  }
  return [...new Set(invalidChars)]; // Remove duplicatas
}

export default store;
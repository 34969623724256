<template>
    <form @submit="onSubmit" class="adCreateForm" id="adCreateForm">
        <div class="title_adCreate">Atualizar anúncio</div>

        <label for="adCategory">Categoria</label>
        <v-select v-model="adCategory" :options="categories" label="title" class="form__category" />

        <label for="adState">Estado</label>
        <v-select v-model="adState" :options="states" @update:modelValue="$store.dispatch('loadCities', $event)"
            label="name" class="form__adSelector" required></v-select>

        <label for="adCity">Cidade</label>
        <v-select v-model="adCity" :options="cities" label="name" @update:modelValue="$store.dispatch('selectCity', $event)"
            class="form__adSelector" required></v-select>

        <label for="adTitle">Título do anúncio</label>
        <input class="form__title" v-model="adTitle" type="text" id="adTitle" name="adTitle"
            placeholder="Digite um título para o anúncio" required>

        <label for="adDescription">Descrição do item</label>
        <textarea class="form__description" v-model="adDescription" type="text" cols="30" rows="10" id="adDescription" name="adDescription"
            placeholder="Digite uma descrição para item" required></textarea>

        <label for="adValue">Valor</label>
        <p class="adValue__text">(Se quiser apenas divulgar as informações na descrição ou em uma das imagens, sem exibir um valor e o botão para sinalizar interesse, informe 0 nesse campo)</p>
        <input class="form__value" v-model="adValue" type="text" id="adValue" name="adValue"
            placeholder="Digite um valor para item" required>

        <div>
            <AdUpdatePhotos />
        </div>

        <div v-if="!this.$store.state.validAd">
            <ul>
                <li class="corrections" v-for="(correction, index) in this.$store.state.correctionsNeeded" :key="index">
                    <img :src="iconTriangle" alt="Ícone de atenção" /> {{ correction }}
                </li>
            </ul>
        </div>
        <div>
            <button :class="{ 'button__form': !is_Submitting, 'button__form_alt': is_Submitting }" type="submit" :disabled="is_Submitting">Enviar</button>
            <button class="button__form" type="reset" @click="hideAdUpdate">Cancelar</button>
            <p class="button__delete" @click="deleteAd">{{ deleteButtonLabel }}</p>
            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate 
                :size="50" 
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import iconTriangle from '@/assets/img/triangle_exclamation.svg';
import iconPlusSign from '@/assets/img/plus_sign.svg';
import iconMinusSign from '@/assets/img/minus_sign.svg';
import defaultAdPic from "../assets/img/no_pic.jpg";
import AdUpdatePhotos from "./adUpdatePhotos.vue";

const toast = useToast();

export default defineComponent({
    name: 'adUpdate',
    components: {
        AdUpdatePhotos,
    },
    setup() {
        const store = useStore();
        const states = computed(() => store.state.theStates);
        const cities = computed(() => store.state.cities);
        
        onBeforeUnmount(() => {
            store.commit('cleanArrays');
        });

        onMounted(() => {
            store.commit('cleanArrays');
            store.dispatch('loadStates');
        });

        return {
            //countries,
            states,
            cities
        }
    },
    ...mapState(['selectedAd']),
    data() {
        return {
            iconTriangle,
            iconPlusSign,
            iconMinusSign,
            adId: null,
            adCategory: null,
            adState: null,
            adCity: null,
            adTitle: null,
            adDescription: null,
            adValue: null,
            isSubmitting: false,

            categories: [
                'Veleiros',
                'Velas, acessórios e peças',
                'Eletrônicos náuticos',
                'Motores',
                'Botes, caiaques e pranchas',
                'Vagas e poitas',
                'Carretas',
                'Serviços',
                'Alimentação',
                'Hospedagem',
                'Cursos',
                'Eventos',
            ],

            deleteState: "none"
        }
    },
    mounted() {
        // Load data of selected Ad to form
        if (this.selectedAd) {
            this.adId = this.selectedAd._id;
            this.adCategory = this.selectedAd.category;
            this.adState = this.selectedAd.state;
            this.adCity = this.selectedAd.city;
            this.adTitle = this.selectedAd.title;
            this.adDescription = this.selectedAd.description;
            this.adValue = this.formatCurrency(this.selectedAd.value);
            //this.photosKept = this.selectedAd.photos;
        }
    },
    watch: {
        adValue(newValue, oldValue) {
            if (typeof newValue === 'string' && newValue !== oldValue) {
                this.adValue = this.formatToCurrency(newValue);
            }
        }
    },
    computed: {
        ...mapState({
            selectedAd: state => state.selectedAd,
            photosKept: state => state.photosKept,
            photosAdded: state => state.photosAdded,
            photosDeleted: state => state.photosDeleted,
            adFilesAdded: state => state.adFilesAdded,
        }),
        deleteButtonClass() {
            switch (this.deleteState) {
                case "none":
                    return "button__delete"
                case "pending":
                    return "button__delete button__delete--pending"
                case "confirmed":
                    return "button__delete button__delete--confirmed"
                default:
                    return "button__delete"
            }
        },
        deleteButtonLabel() {
            switch (this.deleteState) {
                case "none":
                    return "Excluir"
                case "pending":
                    return "Confirmar Exclusão"
                default:
                    return "Excluir"
            }
        },
        is_Submitting() {
            return (this.isSubmitting)
        },
    },
    methods: {
        hideAdUpdate() {
            this.$store.commit('toggleShowAdUpdate');
            this.$store.commit('toggleShowListManager');
            this.$store.commit('resetCorrectionsNeeded');
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            let expiryAt = new Date();
            expiryAt.setFullYear(expiryAt.getFullYear() + 1);

            this.$store.commit('generatePhotosUpdateValidation');

            const ad = {
                _id: this.adId,
                userId: this.$store.state.user._id,
                category: this.adCategory,
                state: this.adState,
                city: this.adCity,
                title: this.adTitle,
                description: this.adDescription,
                value: this.normalizeValue(this.adValue),
                photos: this.$store.state.photosUpdateValidation,
                status: true,
                expiryAt: expiryAt,
                displayPriority: this.$store.state.user.backingFactor,
                createdAt: this.selectedAd.createdAt,
                updatedAt: new Date(),
            };

            const imageFiles = this.adFilesAdded;

            const adToCheck = {
                category: ad.category,
                state: ad.state,
                city: ad.city,
                title: ad.title,
                description: ad.description,
                value: ad.value,
                photos: ad.photos,
                imageFiles
            };


            //console.log("photos", this.$store.state.photosUpdateValidation);

            // Chama a mutation para validar o anúncio.
            this.$store.commit('validateAd', adToCheck);

            if (this.$store.state.validAd) {

                toast.warning('Aguarde... A atualização do anúncio pode demorar alguns instantes.', { timeout: 6000 });
                try {
                    await this.$store.dispatch('updateAd', ad);

                    toast.success(`${this.$store.state.user.name.split(" ")[0]}, seu anúncio foi atualizado com sucesso.`, { timeout: 6000 });

                    this.hideAdUpdate();
                    // this.$store.commit('toggleShowAdUpdate');
                    // this.$store.commit('toggleShowListManager');
                    // this.$store.commit('resetCorrectionsNeeded');
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isSubmitting = false;
                }
            } else {
                toast.warning('Alguns campos do formulário precisam de sua atenção.\nVeja as observações acima do botão de envio.', { timeout: 6000 });
                this.isSubmitting = false;
            }
        },
        async getDefaultAdPic() {
            const response = await fetch(defaultAdPic);
            const blob = await response.blob();
            return new File([blob], 'no_pic.jpg', { type: 'image/jpeg' });
        },
        async deleteAd() {
            switch (this.deleteState) {
                case "none":
                    this.deleteState = "pending"
                    break
                case "pending":
                    this.deleteState = "confirmed"

                    await this.$store.dispatch('deleteAd', this.selectedAd);

                    toast.success(`${this.$store.state.user.name.split(" ")[0]}, seu anúncio foi excluído com sucesso.`, { timeout: 6000 });

                    this.$store.commit('toggleShowAdUpdate');
                    this.$store.commit('toggleShowListManager');
                    break
                default:
                    this.deleteState = "none"
            }
        },
        formatToCurrency(value) {
            // Remove tudo o que não é número
            let number = value.replace(/\D/g, "");

            // Formata no estilo de moeda
            number = (number / 100).toFixed(2) + '';
            number = number.replace(".", ",");
            number = number.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

            return `R$ ${number}`;
        },
        normalizeValue(value) {
            if (typeof value === 'string') {
                value = value.replace('R$ ', ''); // Remove o símbolo R$
                return parseFloat(value.replace(/\./g, '').replace(',', '.'));
            }
            return value; // Retorna o valor como está se não for uma string
        },
        formatCurrency(value) {
            // Formatação de moeda
            if (value !== null && typeof value !== "undefined") {
            let formattedValue = value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            return `R$ ${formattedValue}`;
            }
            return value;
        },
    },
})

</script>

<style scoped>
.title_adCreate {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}

.adCreateForm {
    display: flex;
    flex-direction: column;
    margin: 20px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.adCreateForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.adCreateForm select,
.adCreateForm input[type="image"] {
    width: 320px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}

.form__category {
    width: 320px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

    background: var(--branco-cv);

    --vs-border-style: none;

    --vs-controls-color: var(--azul-scrollbarBkg-cv) --vs-border-color: var(--branco-cv);

    --vs-dropdown-bg: var(--azul-escuro-cv);
    --vs-dropdown-color: var(--branco-cv);
    --vs-dropdown-option-color: var(--branco-cv);

    --vs-selected-bg: var(--branco-cv);
    --vs-selected-color: var(--preto-cv);

    --vs-search-input-color: var(--preto-cv);
    --vs-search-input-placeholder-color: var(--cinza-cv);

    --vs-dropdown-option--active-bg: var(--branco-cv);
    --vs-dropdown-option--active-color: var(--azul-scrollbarBkg-cv)
}

.form__adSelector {
    width: 320px;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

    background: var(--branco-cv);

    --vs-border-style: none;

    --vs-controls-color: var(--azul-scrollbarBkg-cv) --vs-border-color: var(--branco-cv);

    --vs-dropdown-bg: var(--azul-escuro-cv);
    --vs-dropdown-color: var(--branco-cv);
    --vs-dropdown-option-color: var(--branco-cv);

    --vs-selected-bg: var(--branco-cv);
    --vs-selected-color: var(--preto-cv);

    --vs-search-input-color: var(--preto-cv);
    --vs-search-input-placeholder-color: var(--cinza-cv);

    --vs-dropdown-option--active-bg: var(--branco-cv);
    --vs-dropdown-option--active-color: var(--azul-scrollbarBkg-cv);
    color: #eeee;
}

.form__country,
.form__state,
.form__city,
.form__title,
.form__value {
    width: 320px;
    height: 45px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.form__description {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.adValue__text {
    font-size: 16px;
    padding-bottom: 5px;
}
.corrections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    gap: 6px;
    color: var(--laranja-cv);
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__form_alt {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    justify-content: center;
    background-color: var(--cinza-disable-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: #765d5d;
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__delete {
    font-family: Ubuntu;
    font-size: 18px;
    width: 160px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--vermelho-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}
.button__delete:hover {
    background-color: var(--vermelho-cv);
    color: var(--branco-cv);
}

.button__delete--pending {
    background-color: var(--branco-cv);
    color: var(--vermelho-cv);
}
</style>
<template>
    <form @submit="onSubmit" class="loginForm" id="loginForm">
        <div class="title_loginin">Entrar</div>
        <label for="userEmail">Endereço de e-mail</label>
        <input v-model="userEmail" type="email" id="userEmail-login" name="userEmail" placeholder="Digite seu endereço de e-mail"
            required>

        <label for="userPassword">Senha
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input v-model="userPassword" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userPassword-login" name="userPassword" placeholder="Digite sua senha"
            required>
        <p>
            <a @click="forgotPassword">Esqueci minha senha</a>
        </p>
        <div>
            <button class="button__form" type="submit">Entrar</button>
            <button class="button__form" type="reset" @click="hideLogin">Cancelar</button>
            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate 
                :size="50" 
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'loginComponent',
    methods: {
        hideLogin() {
            this.$store.commit('hideAllContentPanel');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
        },
        resetForm() {
            this.userEmail = null;
            this.userPassword = null;
        },
        togglePasswordVisibility() {
            this.$store.commit('togglePasswordVisibility');
        },
        forgotPassword() {
            this.$store.commit('touggleShowLogin');
            this.$store.commit('touggleShowForgotPassword');
            this.resetForm();
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;
            
            const userLogin = {
                email: this.userEmail,
                password: this.userPassword,
            };

            try {

                await this.$store.dispatch('loginUser', userLogin);
                
                this.resetForm();
                this.$store.commit('touggleShowLogin');
                
            } catch (error) {
                console.log(error);
            } finally {
                this.isSubmitting = false;
            }
        }
    },
    data() {
        return {
            userEmail: null,
            userPassword: null,
            isSubmitting: false,
        }
    },
})

</script>

<style scoped>

.title_loginin {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}
.loginForm {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 10px;
    padding: 20px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.loginForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.loginForm select,
.loginForm input[type="email"],
.loginForm input[type="text"],
.loginForm input[type="password"]{
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    background-color: var(--branco-cv);
}

.loginForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
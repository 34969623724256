<template>
    <div class="page__content">
      <section>
        <TopBar/>
      </section>
      <section class = "middleScreen">
        <MiddleScreen/>
      </section>
      <section>
        <BottomBar />
      </section>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import TopBar from "../src/components/topBar.vue"
import MiddleScreen from "../src/components/middleScreen.vue"
import BottomBar from "../src/components/bottomBar.vue"

export default defineComponent({
  name: 'App',
  components: {
    TopBar,
    MiddleScreen,
    BottomBar
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const adId = urlParams.get('adId');
    if (adId) {
      // Implemente a lógica para carregar e exibir o anúncio usando adId
      // Por exemplo, você pode chamar um método Vuex action que carrega o anúncio e atualiza o estado

      try {
        const adFounded = await this.$store.dispatch('loadAdById', adId);
        if (adFounded != null) {
          this.$store.dispatch('selectAdToView', adFounded);
          this.$store.commit('toggleShowAdViewOn');
          this.$store.commit('touggleShowSliderOff');
        }
      } catch (error) {
          console.log(error);
      }
    }
  }
})
</script>

<style>
@import "./assets/css/reset.css";
@import "./assets/css/global.css";

.page__content {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  background-image: url("./assets/img/ocean_bkg.png");
  background-position: center top;
  background-repeat: no-repeat;
}

.middleScreen {
  width: 100%;
  height: auto;
}
</style>

<template>
    <div class="close__terms-top">
        Fechar
        <img class="close__button" :src="isHover ? closeBlue : closeWhite" @mouseover="isHover = true"
        @mouseleave="isHover = false" @click="closeTerms" />
    </div>
    <div class="terms__conditions" v-html="termsAndConditions">
    </div>
    <div class="close__terms-bottom">
        Fechar
        <img class="close__button" :src="isHover ? closeBlue : closeWhite" @mouseover="isHover = true"
        @mouseleave="isHover = false" @click="closeTerms" />
    </div>
</template>

<script>

import { marked } from 'marked';
import termsAndConditions from '../assets/termsAndConditionsOfUse.md';
import closeIconWhite from '../assets/img/close_icon_white.svg';
import closeIconBlue from '../assets/img/close_icon_blue.svg';

export default {
    data() {
      return {
        isHover: false,
        closeWhite: closeIconWhite,
        closeBlue: closeIconBlue,
        termsAndConditions: marked(termsAndConditions),
      };
    },
    methods: {
        closeTerms () {
            this.$store.commit('toggleShowTermsAndConditions');
        }
    }
};
</script>

<style scoped>

.terms__conditions{
    background-color: var(--azul-scrollbarBkg-cv);
    color: var(--branco-cv);
    padding-bottom: 16px;
}

:deep(h1) {
    font-size: 20px;
    padding: 16px 0 0 0;
    text-align: center;
}

:deep(h2) {
    font-size: 18px;
    padding: 16px;
    text-align: right;
}

:deep(h3) {
    font-size: 18px;
    padding: 16px;
    text-align: justify;
}

:deep(h4) {
    font-size: 16px;
    padding: 16px;
    text-align: justify;
}

:deep(p) {
    font-size: 16px;
    padding: 0 16px 0 16px;
    text-align: justify;
    line-height: 22px;
}

:deep(li) {
    font-size: 12;
    padding: 10px 16px 0 16px;
    text-align: justify;
    line-height: 22px;
}

:deep(a), :deep(a:visited) {
    color: var(--branco-cv); 
}

.close__terms-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 25px;
    color: var(--azul-escuro-cv);
    gap: 10px;
}

.close__terms-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 25px;
    color: var(--branco-cv);
    gap: 10px;
}

.close__button {
  font-family: Ubuntu;
  font-size: 18px;
  width: 40px;
  align-items: center;
  justify-content: center;
  color: var(--branco-cv);
  margin: 16px 20px 16px 0px;
  padding: 3px 2px 3px 2px;
  border-radius: 4px;
  border-style: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
}

.close__button:hover {
  color: var(--bege-cv);
  background-color: var(--azul-escuro-cv);
}
</style>
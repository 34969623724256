import { createApp } from 'vue';
import store from './store/store';
import Cookies from 'js-cookie';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'vue-select/dist/vue-select.css';
import VueSelect from "vue-select";
import App from './App.vue';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

let app = createApp(App);

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
})
.component("v-select", VueSelect)
.use(VueAwesomePaginate)
.use(vuetify)
.use(store)

const token = Cookies.get('user_token');

if (token) {
  try {
    await store.dispatch('loadUserAndActivate', token);
  } catch(error) {
    console.log(error);
  }
}

const urlParams = new URLSearchParams(window.location.search);
const userIdReset = urlParams.get('userId');
const resetToken = urlParams.get('token');

if (resetToken) {
  // O token existe, ajusta o estado no Vuex
  store.commit('setUserIdReset', userIdReset);
  store.commit('setUserTokenReset', resetToken);
  store.commit('touggleShowResetPassword');
  store.commit('touggleShowSliderOff');
  store.commit('toggleShowAdsDisplayOff');
} else if (resetToken == "") {
  // O token não existe
  store.commit('touggleShowRedefinitionInvalid');
  store.commit('touggleShowSliderOff');
  store.commit('toggleShowAdsDisplayOff');
}

app.mount('#app');
<template>
  <div v-if="this.$store.state.adsListQuantity > 0 && !this.$store.state.showTermsAndConditions && !this.$store.state.ShowPrivacyPolicy" class="list__display"> 
    
    <ul class="ads">
      <li v-for="ad in getAdsList" :key="ad._id" @click="selectAd(ad)">
        <div class="ads__display__itens" v-if="ad.status">
          <img :src="profileImagePath(ad.photos[0])" alt="Foto do item" class="adPic">
          <p class="ads__title-text">
            {{ ad.title }}
          </p>
          <p v-show="!smallPage" class="ads__description-text">{{ truncateDescription(ad.description) }}</p>
        </div>
      </li>
    </ul>

    <div class="pagination__bar">
      <vue-awesome-paginate 
        v-if="this.$store.state.adsTotalPages > 1"
        :total-items="this.$store.state.adsTotalPages"
        v-model="currentPage"
        :items-per-page="1"
        :max-pages-shown="5"
        :on-click="() => loadAdsPages(this.filters, this.currentPage)">

        <template #prev-button>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="12" height="12" viewBox="0 0 24 24">
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
          </span>
        </template>

        <template #next-button>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="12" height="12" viewBox="0 0 24 24">
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>
  <div class="no_ads" v-if="this.$store.state.adsListQuantity <= 0 && !this.$store.state.showTermsAndConditions">
    <p>Nenhum anúncios disponível no momento...</p>
  </div>
</template>
  
<script>
import { defineComponent, ref } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

const imageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;

export default defineComponent({
  name: 'adsListManager',
  setup() {
    const currentPage = ref(1);
    return {
      currentPage,
    };
  },
  data() {
    return {
      initialPage: 1,
      filters: {
        category: null,
        state: null,
        city: null,
        title: null,
        description: null,
        min: 0,
        max: 0,
        componentKey: 0,
      },
    };
  },
  watch: {
    async showFavouritesAds(newVal, oldVal) {
      if (!newVal && oldVal) {  // changed from true to false
        try {
          const filters = { category: null, state: null, city: null, title: null, description: null, min: 0, max: 0 };
          const page = 1;
          await this.loadAdsByFilter({ filters, page });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapState(['user', 'userActive','selectedAd', 'smallPage']),
    ...mapGetters(['getAdsList']),
    showFavouritesAds() {
      return this.$store.state.showFavouritesAds;
    },
  },
  methods: {
    async loadAdsPages(filters, page) {
      try {
        await this.loadAdsByFilter({ filters, page });
      } catch (error) {
        console.log(error);
      }
    },
    async selectAd(ad) {
      //console.log("Anúncio selecionado: ", ad);
      if (this.userActive) {
        try {
          await this.$store.dispatch('checkFavouriteAd', this.user._id + "_" + ad._id);
        } catch (error) {
          console.log(error);
        }
      }
      this.$store.dispatch('selectAdToView', ad);

      if (this.userActive) {
        const click = { adId: ad._id, origin: "i" };
        this.$store.dispatch('adClickCount', click);
      } else {
        const click = { adId: ad._id, origin: "o" };
        this.$store.dispatch('adClickCount', click);
      }
      
      this.$store.commit('toggleShowAdViewOn');
      this.$store.commit('touggleShowSliderOff');
    },
    profileImagePath(adImage) {
      const adImageName = adImage;
      const timestamp = Date.now();
      return imageStorageLink + adImageName + '?t=' + timestamp;
    },
    ...mapActions(['loadAdsByFilter']),
    truncateDescription(description) {
      if (description.length > 100) {
        return description.substring(0, 100) + '...';
      }
      return description;
    },
  },
  async mounted() {
    try {
      const filters = { category: null, state: null, city: null, title: null, description: null, min: 0, max: 0 };
      const page = 1;
      await this.loadAdsByFilter({ filters, page });
    } catch (error) {
      console.log(error);
    }
  },
});
</script>
  

<style>
.ads {
  width: 100%;
}

.ads__title-text {
  width: 22%;
  font-size: 18px;
}

.ads__description-text {
  font-size: 14px;
  padding: 0 10px 0 5px;
  width: 57%;
  text-align: justify;
}

@media (max-width: 780px) {
  .ads__description-text {
    display: none;
  }
}

.list__display {
  max-height: 500px;
  /* Altere para a altura que você deseja */
  overflow-y: auto;
  /* Exibe barra de rolagem vertical se necessário */
}

.no_ads {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  background-color: var(--azul-escuro-cv);
}

.ads__display__itens {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 10px 10px 0;
  gap: 10px;
  background-color: var(--azul-escuro-cv);
}

.ads__display__itens:hover {
  background-color: var(--bege-cv);
  color: var(--azul-escuro-cv);
}

.adPic {
  display: flex;
  width: 150px;
  border-radius: 1%;
  border: solid;
  border-width: 3px;
  align-content: center;
}

.pagination__bar {
  display: flex;
  justify-content: center;
  padding: 0 0 16px 0;
}

.pagination__bar .paginate-buttons {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #e6e6e6;
  color: var(--azul-escuro-cv);
  border: none;
}

.pagination__bar .back-button,
.pagination__bar .next-button {
  margin-inline: 10px;
  border-radius: 25px;
}

.pagination__bar .first-button {
  border-start-start-radius: 25px;
  border-end-start-radius: 25px;
}

.pagination__bar .last-button {
  border-start-end-radius: 25px;
  border-end-end-radius: 25px;
}

.pagination__bar .back-button svg {
  transform: rotate(180deg) translateY(-2px);
}

.pagination__bar .next-button svg {
  transform: translateY(2px);
}

/* select second element of .paginate-buttons */
.pagination__bar li:nth-child(2)>.paginate-buttons.number-buttons {
  border-start-start-radius: 25px;
  border-end-start-radius: 25px;
  transition: none;
}

/* select one element before last of .paginate-buttons */
.pagination__bar li:nth-last-child(2)>.paginate-buttons.number-buttons {
  border-start-end-radius: 25px;
  border-end-end-radius: 25px;
}

.pagination__bar .active-page {
  background-color: #2980b9;
  color: #fff;
}

.pagination__bar .active-page {
  background-color: #2980b9;
  color: #fff;
}

.pagination__bar .paginate-buttons:hover {
  background-color: #f5f5f5;
}

.pagination__bar .active-page:hover {
  background-color: #388ac1;
}

.pagination__bar .back-button:active,
.pagination__bar .next-button:active {
  background-color: #e6e6e6;
}
</style>
<template>
        <div class="title_adsManager">Gerenciador de Anúncios</div>
        <div v-if="!this.$store.state.showAdCreate && !this.$store.state.showAdUpdate" class="bar_adsManager">
            <div class="button__adsManger" @click="showAdCreate">
                Criar Anúncio
            </div>
            <div class="button__adsManger" v-if="this.$store.state.showListManager" @click="showAdInterested">
                Ver Interessados
            </div>
            <div class="button__adsManger" v-if="this.$store.state.showAdInterested" @click="showAdsList">
                Meus Anúncios
            </div>
        </div>
        <div class="panel_adsManager">
            <AdCreate v-if="this.$store.state.showAdCreate"/>
        </div>
        <div class="panel_adsManager">
            <AdUpdate v-if="this.$store.state.showAdUpdate"/>
        </div>
        <div class="panel_adsManager">
            <AdsListManager v-if="this.$store.state.showListManager"/>
        </div>
        <div class="panel_adsManager">
            <AdsInterested v-if="this.$store.state.showAdInterested"/>
        </div>
</template>

<script>
import { defineComponent } from 'vue';
import AdCreate from './adCreate.vue';
import AdsListManager from "./adsListManager.vue";
import AdsInterested from "./adsInterested.vue";
import AdUpdate from "./adUpdate.vue";

export default defineComponent({
    name: 'adsManager',
    components: {
		AdCreate,
        AdsListManager,
        AdUpdate,
        AdsInterested
    },
    methods: {
        hideadsManager() {
            this.$store.commit('touggleShowadsManager');
            this.$store.commit('hideAllContentPanel');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
        },
        showAdCreate() {
			this.$store.commit('toggleShowAdCreate');
            this.$store.commit('toggleShowListManager');
		},
        showAdInterested() {
			this.$store.commit('toggleShowAdInterested');
            this.$store.commit('toggleShowListManager');
		},
        showAdsList() {
			this.$store.commit('toggleShowAdInterested');
            this.$store.commit('toggleShowListManager');
		},
    },
    data() {
        return {
            
        }
    },
})

</script>

<style scoped>
.panel_adsManager {
    width: 100%;
}
.title_adsManager {
    display: flex;
    font-size: 26px;
    justify-content: center;
    padding: 18px 0 18px 0;
}
.bar_adsManager{
    display: flex;
    justify-content: space-around;
    padding: 0 0 18px 0;
}
.button__adsManger {
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 8px 0px 8px 0px;
    padding: 8px 2px 8px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__adsManger:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
<template>
  <div id="app">
    <Carousel v-if="getSliderPhotos.length > 0" :wrap-around="true">
      <Slide v-for="(photo, index) in getSliderPhotos" :key="index">
        <img class="slide" :src="imagesLinks(photo.name)" />
        <div class="caption" v-show="!isSmallPage && photo.value != '0'">
          {{ index !== null && getSliderPhotos[index] ? getSliderPhotos[index].caption : '' }}
        </div>
      </Slide>
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import { mapGetters } from 'vuex';
import 'vue3-carousel/dist/carousel.css'

const imageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      currentSlide: null,
    }
  },
  computed: {
    ...mapGetters(['getSliderPhotos']),
    isSmallPage() {
			return this.$store.state.smallPage;
		},
    imagesLinks() {
      return (image) => imageStorageLink + image;
    },
  }
})

</script>

<style>
.caption {
  color: var(--branco-cv);
  font-size: 40px;
  text-shadow: 2px 2px 3px var(--preto-cv);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  opacity: 1;
  padding: 10px 12px 10px 12px;
  
}


.carousel__prev {
  position: absolute;
  transform: translateY(-50%);
  --vc-pgn-height: 12px;
  --vc-pgn-background-color: var(--branco-cv);
  --vc-pgn-border-radius: 50%;
  height: 80%;
  width: 60px;
  margin: 0;
  left: 0;
}
.carousel__prev:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.carousel__next {
  position: absolute;
  transform: translateY(-50%);
  --vc-pgn-height: 12px;
  --vc-pgn-background-color: var(--branco-cv);
  --vc-pgn-border-radius: 50%;
  height: 80%;
  width: 60px;
  margin: 0;
  right: 0;
}
.carousel__next:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
</style>
<template>
    <div class="adView">
        <div class="adView__category" :class="{ 'adView__category_alt': smallPage, 'adView__category': !smallPage}">
            <p class="adView__category-text">Categoria {{ getSelectedAdToView.category }}</p>
            <div class="adView__share">
                <p>Compartilhar</p>
                <img class="adView__share_icon" :src="isHoverShare ? shareOrange : shareWhite" @mouseover="isHoverShare = true" @mouseleave="isHoverShare = false" @click="copyShareLink" />
            </div>
            <div class="close_ad">
                <p>Fechar Anúncio</p>
                <img class="adView__close" :src="isHover ? closeBlue : closeWhite" @mouseover="isHover = true" @mouseleave="isHover = false" @click="closeAdView" />
            </div>
        </div>
        <Carousel v-if="getSelectedAdToView.photos.length > 0" :wrap-around="true">
            <Slide v-for="(photo, index) in getSelectedAdToView.photos" :key="index">
                <img class="slide" :src="imagesLinks(photo)" />
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
        <div class="adView__data">
            <div>
                <p class="adView__title">{{ getSelectedAdToView.title }}</p>
                <p class="adView__published-date" v-if="!smallPage">Publicado em {{ formatDate(getSelectedAdToView.createdAt) }} | Atualizado em {{ formatDate(getSelectedAdToView.updatedAt) }}</p>
                <p class="adView__published-date" v-if="smallPage">Publicado em {{ formatDate(getSelectedAdToView.createdAt) }}</p>
                <p class="adView__published-date" v-if="smallPage">Atualizado em {{ formatDate(getSelectedAdToView.updatedAt) }}</p>
                <p class="adView__description-field">{{ getSelectedAdToView.description }}</p>
            </div>
            <div class="adView__state-city-value">
                <div class="adView__state">
                    <p class="adView__state-text">Estado</p>
                    <p class="adView__state-field">{{ getSelectedAdToView.state }}</p>
                </div>
                <div class="adView__city">
                    <p class="adView__city-text">Cidade</p>
                    <p class="adView__city-field">{{ getSelectedAdToView.city }}</p>
                </div>
                <div class="adView__value" v-if="getSelectedAdToView.value > 0">
                    <p class="adView__value-text">Valor</p>
                    <p class="adView__value-field">{{ formatCurrency(getSelectedAdToView.value) }}</p>
                </div>
            </div>
            <div v-if="(!userActive && getSelectedAdToView.value > 0)" class="adView__contact">
                    <p>Para marcar o anúncio como favorito, fazer contato com o anunciante,<br>
                    ver a classifição ou classificar o anunciante faça login.</p>
            </div>

            <div class="adView__title-favourite">
                <img class="adView__favourite" @click="changeFavourite"
                    :src="this.getFavouritedAdStatus ? heartOrange : heartWhite" />
                <p>Favoritar Anúncio</p>
            </div>

            <div v-if="userActive && getSelectedAdToView.value > 0 && this.interestedStatus == null && (this.user._id != this.getSelectedAdToView.userId._id)">
                <form @submit="onSubmit" class="contact__form" id="contac__form">
                    <div class="contact__check">
                        <input v-model="contact__checkbox" type='checkbox' id="contact__checkbox" name="contactCode"
                            placeholder="Digite o código aqui" required>
                        Eu entendo e concordo que ao clicar no botão "Sinalizar Interesse", o anunciante receberá um aviso
                        sobre o meu interesse a respeito do item por ele anunciado para que ele possa entrar em contato
                        comigo. E que o aviso conterá a minha foto do perfil, meu primeiro nome, além do meu número de
                        celular e meu endereço de e-mail.
                    </div>
                    <div class="captcha__text">
                        <p>Digite no campo abaixo as letras e números que você vê no captcha.</p>
                    </div>
                    <div class="captcha">
                        <input class="captcha__field" type="text" v-model="inputValue" required />
                        <VueClientRecaptcha :value="inputValue" @getCode="getCaptchaCode" @isValid="checkValidCaptcha" />
                    </div>
                    <button :class="{ 'button__form': contactAgreed, 'button__form_alt': !contactAgreed}" type="submit"
                        :disabled="!contactAgreed">Sinalizar Interesse</button>
                    <v-progress-circular v-if="isSubmitting"
                        color="blue-lighten-3"
                        indeterminate 
                        :size="50" 
                        :width="10">
                    </v-progress-circular>    
                </form>
            </div>

            <div v-if="userActive && advertiser && (this.user._id != this.getSelectedAdToView.userId._id)" class="user_rate" >
                <div class="adView__rating">
                    <div class="advertiser">
                        <p>Anunciante</p>
                        <img class="advertiserProfile__Image" :src="profileImagePath" alt="Foto do usuário">
                        <p class="advertiserProfile__name">{{ advertiser.name.split(" ")[0] }}</p>
                        <p class="advertiserProfile__empresa-text" v-if="advertiser.company!='none'">Representante da empresa</p>
                        <p class="advertiserProfile__empresa" v-if="advertiser.company!='none'">{{ advertiser.company}}</p>
                        <p class="advertiserProfile_date">No ClassiVela há {{ displayDuration(advertiser.createdAt)}}</p>
                        <p class="advertiserProfile_date" v-if="advertiser.isBackerSince != null">Apoiador há {{ displayDuration(advertiser.isBackerSince)}}</p>
                    </div>
                </div>
            </div>

            <div v-if="userActive && getSelectedAdToView.value > 0 && this.interestedStatus == 'new' && (this.user._id != this.getSelectedAdToView.userId._id)">
                <p class="contact_info">A sinalização do seu interesse pelo anúncio já foi enviada para o anunciante. Aguarde que o anunciante corresponda seu interesse. Caso isso não ocorra em 7 dias você poderá sinalizar o seu interesse novamente, se o anúncio ainda estiver ativo.</p>
            </div>
            <div v-if="userActive && getSelectedAdToView.value > 0 && this.interestedStatus == 'wait' && (this.user._id != this.getSelectedAdToView.userId._id)">
                <p class="contact_info">Anunciante está ciente de seu interesse e sinalizou que você aguarde pelo contato dele.</p>
            </div>
            <div v-if="userActive && getSelectedAdToView.value > 0 && this.interestedStatus == 'contact' && (this.user._id != this.getSelectedAdToView.userId._id)">
                <p class="contact_info">Anunciante está ciente de seu interesse e sinalizou para que você faça contato com ele.</p>
                <p class="contact_info">Faça contato com {{ getSelectedAdToView.userId.name.split(" ")[0] }} pelo e-mai ou telefone abaixo.</p>
                <p class="advertiser_info">E-mail: {{ getSelectedAdToView.userId.email }}</p>
                <p class="advertiser_info">Celular: {{ getSelectedAdToView.userId.cellPhone}}</p>
            </div>
            <div v-if="(userActive && advertiser) && (this.user._id != this.getSelectedAdToView.userId._id)" class="user_rate">
                <div class="adView__rating">
                    <h3>Sua classificação para as negociações</h3>
                    <h3>feitas com este anunciante</h3>
                    <div class="stars">
                    <img v-for="n in 5" :key="n"
                        :src="n <= userRating ? starOrange : starWhite"
                        @click="setUserRating(n)" />
                    </div>
                </div>
                <div class="rate__text">
                        <h3>Classificação geral para</h3>
                        <h3>as negociações do anunciante</h3>
                    </div>
                <div class="rateDistribution">
                    <div v-for="(count, star) in rateDistribution" :key="star" class="rateItem">
                        <span class="stars__rating">
                        <img v-for="n in parseInt(star)" :key="n" :src=starOrange />
                        </span>
                        <span class="count">{{ count }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import { mapGetters, mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import VueClientRecaptcha from 'vue-client-recaptcha';
import heartIconWhite from '../assets/img/heart_white.svg';
import heartIconOrange from '../assets/img/heart_orange.svg';
import starIconWhite from '../assets/img/star_white.svg';
import starIconOrange from '../assets/img/star_orange.svg';
import closeIconWhite from '../assets/img/close_icon_white.svg';
import closeIconBlue from '../assets/img/close_icon_blue.svg';
import shareIconWhite from '../assets/img/share_nodes_white.svg';
import shareIconOrange from '../assets/img/share_nodes_orange.svg';
import 'vue3-carousel/dist/carousel.css'

const toast = useToast();
const imageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;
const imageUserStorageLink = process.env.VUE_APP_USER_PROFILE_IMAGES_URL;
import profileImageDefault from "../assets/img/userProfileImage_default.jpg";

export default defineComponent({
    name: 'adView',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        VueClientRecaptcha,
    },
    data() {
        return {
            isHover: false,
            isHoverShare: false,
            isIconClicked: false,
            heartWhite: heartIconWhite,
            heartOrange: heartIconOrange,
            starWhite: starIconWhite,
            shareWhite: shareIconWhite,
            shareOrange: shareIconOrange,
            starOrange: starIconOrange,
            closeWhite: closeIconWhite,
            closeBlue: closeIconBlue,
            currentSlide: null,
            inputValue: null,
            isValidCaptcha: false,
            contact__checkbox: null,
            userRating: null,
            rateDistribution: null,
            profileImageDefault: null,
            advertiserProfileImage: null,
            interestedStatus: null,
            isSubmitting: false,
        }
    },
    computed: {
        ...mapGetters(['getSelectedAdToView', 'getFavouritedAdStatus']),
        ...mapState(['user', 'userActive', 'favouritedAd', 'advertiser', "smallPage"]),
        isSmallPage() {
            return this.$store.state.smallPage;
        },
        imagesLinks() {
            return (image) => imageStorageLink + image;
        },
        contactAgreed() {
            return (this.contact__checkbox && this.isValidCaptcha)
        },
        profileImagePath() {
            if (this.advertiser) {
                const timestamp = Date.now();
                const profileImage = this.advertiser.profileImage
                return imageUserStorageLink + profileImage + '?t=' + timestamp;
            } else {
                return profileImageDefault;
            }
        },

    },
    watch: {
        getSelectedAdToView() {
            // Quando getSelectedAdToView muda, resete o checkbox e limpe o campo do captcha
            this.contact__checkbox = false;
            this.inputValue = '';
            this.isIconClicked = this.favouritedAd;
            this.interestedInAd();
            this.getAdvertiserData();
            this.getGivedUserRate();
            this.getUserRate();
        },
    },
    mounted() {
        this.interestedInAd();
        this.getAdvertiserData();
        this.getUserRate();
    },
    methods: {
        async interestedInAd() {
            if (this.userActive) {
                const adId = this.getSelectedAdToView._id;
                const userId = this.user._id;
                try {
                    const result = await this.$store.dispatch('getInterestedStatus', { adId: adId, userInterestedId: userId });

                    if (result != "") {
                        this.interestedStatus = result;
                    } else {
                        this.interestedStatus = "";
                    }

                } catch(error) {
                    console.log(error);
                }
            }  
        },
        async changeFavourite() {
            if (this.userActive) {
                if (this.getFavouritedAdStatus == false) {
                    const adFavourited = { favouriteId: this.user._id + "_" + this.getSelectedAdToView._id, createdAt: new Date() };
                    try {
                        const result = await this.$store.dispatch('setAddToFavourites', adFavourited);
                        if (result == true) {
                            this.isIconClicked = true;
                            toast.success('Anúncio adicionado com sucesso aos seus favoritos!', { timeout: 6000 });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } else if (this.getFavouritedAdStatus == true) {
                    const adUnFavourited = this.user._id + "_" + this.getSelectedAdToView._id;
                    try {
                        const result = await this.$store.dispatch('removeAddFromFavourites', adUnFavourited);
                        if (result == false) {
                            this.isIconClicked = false;
                            toast.success('Anúncio removido com sucesso dos seus favoritos!', { timeout: 6000 });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            } else {
                toast.warning('Faça login para favoritar os anúncios de seu interesse.', { timeout: 6000 });
            }
        },
        async getAdvertiserData() {
            if (this.userActive) {
                try {
                    // Chame a API aqui para obter a classificação do usuário anunciante
                    const response = await this.$store.dispatch('loadAdvertiser', this.getSelectedAdToView.userId._id);
                    this.advertiserProfileImage = response.profileImage;

                } catch (error) {
                    console.error("Anunciante não encontrado!", error);
                }
            }
        },
        async getUserRate() {
            try {
                // Chame a API aqui para obter a classificação do usuário anunciante
                const response = await this.$store.dispatch('getUserRate', this.getSelectedAdToView.userId._id);
                // Vamos assumir que a resposta é algo como { myRating: 3, distribution: {5: 10, 4: 20, 3: 30, 2: 5, 1: 2} }
                this.rateDistribution = response;
            } catch (error) {
                console.error("Erro ao buscar a classificação do usuário:", error);
            }
        },
        async setUserRating(starRate) {
            if (this.userActive) {
                if (this.user._id != this.getSelectedAdToView.userId._id) {
                    try {
                        await this.$store.dispatch('setUserRate', { userId: this.user._id, userAdsId: this.getSelectedAdToView.userId._id, starRate });
                        // Atualizar a classificação localmente
                        this.userRating = starRate;
                        this.getUserRate();
                    } catch (error) {
                        console.error("Erro ao definir a classificação:", error);
                    }
                }
            } else {
                toast.warning('Faça login para poder dar sua classificação\ne ver a classificação geral do anunciante.', { timeout: 6000 });
            }
        },
        async getGivedUserRate() {
            if (this.userActive) {
                if (this.user._id != this.getSelectedAdToView.userId._id) {
                    try {
                        const response = await this.$store.dispatch('getGivedUserRate', this.user._id + "_" + this.getSelectedAdToView.userId._id);
                        this.userRating = response;
                    } catch (error) {
                        console.error("Erro ao buscar a classificação:", error);
                    }
                }
            } else {
                toast.warning('Faça login para poder dar sua classificação\ne ver a classificação geral do anunciante.', { timeout: 6000 });
            }
        },
        closeAdView() {
            this.$store.commit('toggleShowAdView');
        },
        copyShareLink() {
            const adId = this.getSelectedAdToView._id;
            const shareUrl = `${window.location.origin}/?adId=${adId}`;
            navigator.clipboard.writeText(shareUrl).then(() => {
                toast.success('Link do anúncio copiado para a área de transferência!', { timeout: 6000 });
            }, (err) => {
                console.error('Erro ao copiar o link: ', err);
                toast.error('Erro ao copiar o link.', { timeout: 6000 });
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        // eslint-disable-next-line no-unused-vars
        getCaptchaCode(capthaResult) {
            /* you can access captcha code */
            //console.log(capthaResult);
        },
        checkValidCaptcha(capthaResult) {
            /* expected return boolean if your value and captcha code are same return True otherwise return False */
            //console.log("this is captha valid " + capthaResult);
            this.isValidCaptcha = capthaResult;
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            if (this.contact__checkbox) {
                if (this.isValidCaptcha) {
                    //console.log("Contact Ok!");
                    if (this.getFavouritedAdStatus == false) {
                        this.changeFavourite();
                    }
                    const contact = { adId: this.getSelectedAdToView._id, userId: this.$store.state.user._id }
                    try {
                        await this.$store.dispatch('sendUserContact', contact);
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.interestedInAd();
                        this.isSubmitting = false;
                    }
                } else {
                    toast.warning('O captcha digitado não confere, tente novamente.', { timeout: 6000 });
                }
            } else {
                toast.warning('Para enviar seus dados de contato ao anunciante você precisa marcar a confirmação de que entende e concorda com o envio.', { timeout: 6000 });
            }
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        },
        formatDuration(dateString) {
            const startDate = new Date(dateString);
            const endDate = new Date();

            let months;
            months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
            months -= startDate.getMonth();
            months += endDate.getMonth();

            const years = Math.floor(months / 12);
            months = months % 12;

            return {
                years: years,
                months: months
            };
        },
        displayDuration(dateString) {
            const duration = this.formatDuration(dateString);
            let result = "";

            if (duration.years > 0) {
                result += `${duration.years} ano${duration.years > 1 ? 's' : ''} `;
            }
            if (duration.months > 0) {
                result += `${duration.years > 0 ? ' e ' : ''}${duration.months} ${duration.months > 1 ? 'meses' : 'mês'}`;
            } else if (duration.years < 1) {
                result = "menos de um mês."
            }

            return result;
        }
    }
})
</script>

<style scoped>

.close_ad {
    display: flex;
    align-items: center;
    font-size: 20px;
}
.adView__category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.adView__category_alt {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.adView__category-text {
    font-size: 20px;
    padding: 16px 0 16px 16px;
}

.adView__share {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.adView__share_icon {
    padding: 5px 5px 2px 5px;
    cursor: pointer;
}

.adView__close {
    padding: 2px 5px 2px 16px;
}

.adView__title-favourite {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 0 16px 0;
}

.adView__title {
    font-size: 24px;
    text-align: center;
    padding: 16px 10px 20px 10px;
}

.adView__favourite {
    font-size: 48px;
    cursor: pointer;
}

.adView__description-field {
    font-size: 16px;
    text-align: justify;
    padding: 0 16px 16px 16px;
    background-color: var(--branco-cv);
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    margin: 0 16px 16px 16px;
    padding: 10px 10px 10px 10px;
}

.adView__state-city-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media (max-width: 885px) {

    .adView__state-city-value {
        justify-content: center;
    }
}

.adView__state,
.adView__city,
.adView__value {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
}

.adView__state-text,
.adView__city-text,
.adView__value-text {
    font-size: 18px;
}

.adView__state-field,
.adView__city-field,
.adView__value-field {
    font-size: 16px;
    width: 255px;
    padding: 0 16px 16px 16px;
    background-color: var(--branco-cv);
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    margin: 0 16px 16px 16px;
    padding: 10px 10px 10px 10px;
}

.adView__contact {
    display: flex;
    font-size: 19px;
    color: var(--amarelo-cv);
    padding: 16px 0px 16px 0px;
    justify-content: center;
    text-align: center;
    align-items: center
}

.contact__form {
    display: flex;
    flex-direction: column;
    margin: 20px 60px 0 60px;
    justify-content: center;
    text-align: center;
    align-items: center
}

.captcha {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;
    align-items: center;
    flex-wrap: wrap;
}

.captcha__text {
    padding: 20px 0 5px 0;
}

.captcha__field {
    height: 30px;
    width: 100px;
    padding: 5px;
    background-color: var(--branco-cv);
}

.vue_client_recaptcha {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 0 0 10px;
}

.button__form_alt {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    justify-content: center;
    background-color: var(--cinza-disable-cv);
    margin: 30px 0 20px 0;
    padding: 12px 2px 12px 2px;
    color: #424a5c;
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 30px 0 20px 0;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.carousel__prev {
    position: absolute;
    transform: translateY(-50%);
    --vc-pgn-height: 12px;
    height: 80%;
    width: 60px;
    margin: 0;
    left: 0;
}

.carousel__prev:hover {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.carousel__next {
    position: absolute;
    transform: translateY(-50%);
    --vc-pgn-height: 12px;
    height: 80%;
    width: 60px;
    margin: 0;
    right: 0;
}

.carousel__next:hover {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.user_rate {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 16px 0 0px 0;   
}

.contact_info {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    color: var(--amarelo-cv);
    padding: 0px 16px 16px 16px;
}

.advertiser_info {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    color: var(--branco-cv);
    padding: 0 16px 10px 16px;
}

.adView__rating {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px 0 0px 0; 
}

.rate__text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rateDistribution {
    padding: 16px 0 16px 0;  
}

.rateItem {
    display: flex;
    align-items: center;
}

.stars img {
    width: 28px;
    height: 28px;
}

.stars__rating img {
    width: 20px;
    height: 20px;
}

.count {
    margin-left: 10px;
    /* Estilo para a contagem */
}

.advertiser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px 0;
}

.advertiserProfile__Image {
    display: block;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
    border: solid;
    border-width: 3px;
}

.advertiserProfile__name {
    font-size: 16px;
    color: var(--branco-cv);
    text-align: center;
    padding: 0 0 12px 0;
}

.advertiserProfile_date {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding: 0 0 4px 0;
}

.advertiserProfile_date-text {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding: 0 0 4px 0;
}

.advertiserProfile_date-since-text {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding: 10px 0 4px 0;
}

.adView__published-date {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding: 0 0 6px 0;
}

.advertiserProfile__empresa-text {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding-bottom: 3px;
}
.advertiserProfile__empresa {
    font-size: 14px;
    color: var(--branco-cv);
    text-align: center;
    padding-bottom: 10px;
}
</style>
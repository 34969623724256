<template>
	<div v-show="(!this.$store.state.showCategories && isSmallPage) || (!isSmallPage)"  class="main__content">
		<div :class="{ 'content__slider-alt': isSmallPage, 'content__slider': !isSmallPage }" v-if="sliderVisible">
			<PhotoSlider />
		</div>
		<div class="content__login" v-if="this.$store.state.showLogin">
			<LoginComponent />
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showUserSignup">
			<UserSignup/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showForgotPassword">
			<ForgotPassword/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showResetPassword">
			<ResetPassword/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showUserUpdate">
			<UserUpdate/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showUserDelete">
			<UserDelete/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showUserChangePassword">
			<UserChangePassword/>
		</div>
		<div class="content__userProfile" v-if="this.$store.state.showRedefinitionInvalid">
			<RedefinitionInvalid/>
		</div>
		<div class="content__adsManager" v-if="this.$store.state.showAdsManager">
			<AdsManager/>
		</div>
		<div class="content__adsManager" v-if="this.$store.state.showAdView">
			<AdView/>
		</div>
		<div class="content__adsFilterPanel" v-if="this.$store.state.showAdsFilterPanel">
			<AdsFilterPanel/>
		</div>
		<div :class="{'content__adsDisplay-alt': isSmallPage, 'content__adsDisplay': !isSmallPage}" v-if="adsDisplay">
			<AdsDisplay/>
		</div>
		<div v-if="this.$store.state.showTermsAndConditions">
			<TermsAndConditionsOfUse/>
		</div>
		<div v-if="this.$store.state.ShowPrivacyPolicy">
			<privacyPolicy/>
		</div>
	</div>
</template>

<script>

import { defineComponent } from 'vue';
import PhotoSlider from './photoSlider.vue';
import LoginComponent from './loginComponent.vue';
import UserSignup from './userSignup.vue';
import ForgotPassword from './forgotPassword.vue';
import ResetPassword from './resetPassword.vue';
import UserUpdate from './userUpdate.vue';
import UserDelete from './userDelete.vue'
import UserChangePassword from './userChangePassword.vue'
import RedefinitionInvalid from './redefinitionInvalid.vue';
import AdsManager from './adsManager.vue';
import AdsDisplay from './adsDisplay.vue';
import AdView from './adView.vue';
import AdsFilterPanel from './adsFilterPanel.vue';
import TermsAndConditionsOfUse from './termsAndConditionsOfUse.vue';
import privacyPolicy from './privacyPolicy.vue';

export default defineComponent({
	components: {
    PhotoSlider,
    LoginComponent,
    UserSignup,
	ForgotPassword,
	ResetPassword,
    UserUpdate,
    UserDelete,
    UserChangePassword,
	RedefinitionInvalid,
    AdsManager,
    AdsDisplay,
    AdView,
    AdsFilterPanel,
	TermsAndConditionsOfUse,
	privacyPolicy,
},
	computed: {
		sliderVisible() {
			if (!this.$store.state.showLogin &&
				!this.$store.state.showUserSignup &&
				!this.$store.state.showForgotPassword &&
				!this.$store.state.showResetPassword &&
				!this.$store.state.showUserUpdate &&
				!this.$store.state.showUserDelete &&
				!this.$store.state.showUserChangePassword &&
				!this.$store.state.showRedefinitionInvalid &&
				!this.$store.state.showAdCreate &&
				!this.$store.state.showAdsManager &&
				!this.$store.state.showAdView &&
				!this.$store.state.showTermsAndConditions &&
				!this.$store.state.ShowPrivacyPolicy &&
				!this.$store.state.showSlider) {
					this.$store.commit('touggleShowSlider');
				}
			return this.$store.state.showSlider;
		},
		adsDisplay() {
			if (!this.$store.state.showLogin &&
				!this.$store.state.showUserSignup &&
				!this.$store.state.showForgotPassword &&
				!this.$store.state.showResetPassword &&
				!this.$store.state.showUserUpdate &&
				!this.$store.state.showUserDelete &&
				!this.$store.state.showUserChangePassword &&
				!this.$store.state.showRedefinitionInvalid &&
				!this.$store.state.showAdCreate &&
				!this.$store.state.showAdsManager &&
				!this.$store.state.showAdView &&
				!this.$store.state.showAdsDisplay &&
				!this.$store.state.showFavouritesAds) {
					this.$store.commit('toggleShowAdsDisplay');
				}
			return this.$store.state.showAdsDisplay;
		},
		isSmallPage() {
			return this.$store.state.smallPage;
		}
	}
})

</script>

<style scoped>
.main__content{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	overflow: hidden;
	width: 100%;
}

@media (max-width: 600px) {
    .main__content {
        width: 100vw;
    }
}
.content__slider {
	padding-left: 10px;
	padding-right: 10px;
	height: auto;
	overflow: hidden;
}

.content__slider-alt {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
	height: auto;
	overflow: hidden;
}

.content__login {
	padding-bottom: 10px;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.content__userProfile {
	padding: 0 10px 0 10px;
	height: auto;
	overflow: hidden;
}

.content__adsManager {
	margin: 0 10px 0 10px;
	height: auto;
	overflow: hidden;
	background-color: var(--azul-scrollbarBkg-cv);
}

.content__adsDisplay {
	padding-left: 10px;
	padding-right: 10px;
	padding: 10px 10px 0 10px;
	height: auto;
	overflow: hidden;
}

.content__adsDisplay-alt {
	padding-left: 10px;
	padding-bottom: 20px;
	width: 96vw;
	height: auto;
	overflow: hidden;
}

.content__adsFilterPanel {
	padding: 10px 10px 0 10px;
	height: auto;
	overflow: hidden;
}
</style>
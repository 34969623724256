<template>
    <div class=links__acess>
        <MobileMenu class="iconMobile" v-show="isSmallPage"/>
        <div class="buttons__access">
            <div class="button__access" @click="showLogin" v-show="!this.$store.state.showLogin && !this.$store.state.userActive">
                <p>Entrar</p>
            </div>
            <div class="button__access" @click="logout" v-show="this.$store.state.userActive">
                <p>Sair</p>
            </div>
            <div class="pipebar" v-show="!this.$store.state.showUserSignup && !this.$store.state.showLogin && !this.$store.state.userActive">|</div>
            <div class="button__access" @click="showUserSignup" v-show="!this.$store.state.showUserSignup && !this.$store.state.userActive">
                <p>Criar Perfil</p>
            </div>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import MobileMenu from "./mobileMenu.vue";

export default defineComponent({
    name: "loginStatus",
    components: {
        MobileMenu,
    },
    methods: {
        showLogin() {
            this.$store.commit('hideAllContentPanel');
			this.$store.commit('touggleShowLogin');
		},
        logout() {
            this.$store.dispatch('logoutUser');
            this.$store.commit('hideAllContentPanel');
		},
        showUserSignup() {
            this.$store.commit('hideAllContentPanel');
			this.$store.commit('touggleShowUserSignup');
		},
    },
    computed: {
        ...mapState(['userActive']),
        isSmallPage() {
            return this.$store.state.smallPage;
        },
    }
})

</script>

<style scoped>
.links__acess {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .links__acess {
    display: flex;
    justify-content: space-between;
  }
}

.pipebar {
    margin: 6px;
}

.buttons__access {
    display: flex;
}

.button__access {
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 5px 2px 5px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__access:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.iconMobile {
padding: 3px 0 0 3px;
justify-content: center;
vertical-align: middle;
color: var(--branco-cv);
}
</style>
<template>
  <div class="topBar">
    <!--<SiteLogo class="siteLogo" />-->
    <div class="siteLogo"></div>
    <div :class="{ 'iconMobileAndProfile-alt': !userActive, 'iconMobileAndProfile': userActive }">
      <UserProfileImage/>
    </div>
    <LoginStatus class="loginStatus"/>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import UserProfileImage from "./userProfileImage.vue";
import LoginStatus from "./loginStatus.vue";

export default defineComponent({
name: 'topBarComponent',
components: {
  UserProfileImage,
  LoginStatus
},
computed: {
  ...mapState(['userActive']),
  isSmallPage() {
    return this.$store.state.smallPage;
  },
}
})

</script>

<style scoped>
.topBar {
display: flex;
width: 100%;
flex-wrap: wrap;
align-items: flex-start;
align-content: flex-end;
gap: 10px;
flex-grow: 1;
justify-content: space-between;

}


@media (max-width: 600px) {
.topBar {

}
}

@media (min-width: 600px) {
.topBar {
  justify-content: right;
}
}

.iconMobileAndProfile {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-end;
  justify-content: space-between;
}

.iconMobileAndProfile-alt {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
}

@media (min-width: 600px) {
  .iconMobileAndProfile {
    /*justify-content: flex-end;*/
    max-width: 1200px;
    padding: 0 27px 0 27px;
  }
  .iconMobileAndProfile-alt {
    justify-content: center;
  }
}

.siteLogo {
width: 100%;
height: 150px;
background-image: url(../assets/img/classivela_banner_compressed.png);
background-size: cover; /* alterado de contain para cover */
background-repeat: no-repeat;
background-position: center top;
}

@media (min-width: 600px) {
  .siteLogo {
    height: 278px;
  }
}

.userProfileImage {
align-self: flex-end;
justify-content: center;
margin-top: 10px;
margin-left: 10px;
margin-right: 10px;
}

.loginStatus {
flex-grow: 1;
padding: 0 10px 0 0;
font-size: 20px;
text-align: center;
vertical-align: middle;
color: var(--branco-cv);
}

.iconMobile {
padding: 3px 0 0 12px;
justify-content: center;
vertical-align: middle;
color: var(--branco-cv);
}

/*
@media (min-width: 600px) {
.iconMobile {
  display: none;
}
}*/
</style>
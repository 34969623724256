<template>
  <div class="filter-component" id="filter-component">
    <div class="filter__header">
      <div>Categoria: {{ adsSelectedFilterCategory }}</div>
      <div class="button__filters" @click="displayAdsFilters">Mais filtros</div>
      <div class="close_ad">
        <p>Fechar Categoria</p>
        <img class="filters__close" :src="isHover ? closeBlue : closeWhite" @mouseover="isHover = true"
          @mouseleave="isHover = false" @click="closeAdCategoryFilter" />
      </div>
    </div>
    <div v-if="userActive && showAdsFilters" class="filter__fields">
      <div class="state__city">
        <div class="state">
          <label for="state">Estado</label>
          <v-select v-model="filters.state" :options="states" @update:modelValue="$store.dispatch('loadCities', $event)"
            label="name" class="filter__selector"></v-select>
        </div>
        <div class="city">
          <label for="city">Cidade</label>
          <v-select v-model="filters.city" :options="cities" label="name"
            @update:modelValue="$store.dispatch('selectCity', $event)" class="filter__selector"></v-select>
        </div>
      </div>

      <div class="title__description">
        <div class="title">
          <label for="title">Título</label>
          <input class="title__text-field" id="title" v-model="filters.title" type="text">
        </div>
        <div class="description">
          <label for="description">Descrição</label>
          <input class="description__text-field" id="description" v-model="filters.description" type="text">
        </div>
      </div>

      <div class="valueMin__valueMax">
        <div class="valueMin">
          <label for="min">Valor Mínimo</label>
          <input class="valueMin__text-field" id="min" v-model.number="filters.min" type="number">
        </div>
        <div class="valueMax">
          <label for="max">Valor Máximo</label>
          <input class="valueMax__text-field" id="max" v-model.number="filters.max" type="number">
        </div>
      </div>
      <div class="corretions__messages" v-if="!this.isValidated">
        <ul>
          <li class="corrections" v-for="(correction, index) in this.correctionsNeeded" :key="index">
            <img :src="exclamation" alt="My Icon" /> {{ correction }}
          </li>
        </ul>
      </div>
      <div class="button__div">
        <button class="button__search" @click="submitFilters">Buscar</button>
      </div>
    </div>
  </div>
</template>
  
<script>

import { defineComponent, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore, mapActions, mapState, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification'
import closeIconWhite from '../assets/img/close_icon_white.svg';
import closeIconBlue from '../assets/img/close_icon_blue.svg';
import iconExclamation from '@/assets/img/triangle_exclamation.svg';

const toast = useToast();

export default defineComponent({
  name: 'adsFilterPanel',
  setup() {
    const store = useStore()
    const states = computed(() => store.state.theStates);
    const cities = computed(() => store.state.cities);

    onBeforeUnmount(() => {
      store.commit('cleanArrays');
    });

    onMounted(() => {
      if (store.state.userActive) {
        store.commit('cleanArrays');
        store.dispatch('loadStates');
      }
    });

    return {
      //countries,
      states,
      cities
    }
  },
  data() {
    return {
      isHover: false,
      closeWhite: closeIconWhite,
      closeBlue: closeIconBlue,
      exclamation: iconExclamation,
      isValidated: false,
      correctionsNeeded: [],
      filters: {
        category: null,
        state: null,
        city: null,
        title: null,
        description: null,
        min: 0,
        max: 0,
      },
      categories: [
        'Veleiros',
        'Velas, acessórios e peças',
        'Eletrônicos náuticos',
        'Motores',
        'Botes, caiaques e pranchas',
        'Vagas e poitas',
        'Carretas',
        'Serviços',
        'Alimentação',
        'Hospedagem',
        'Cursos',
        'Eventos',
      ],
    };
  },
  methods: {
    ...mapActions([
      'loadAdsByFilter'
    ]),
    submitFilters() {
      //console.log("Filtros:", this.filters);
      this.validateFilters();

      if (this.isValidated === true) {
        this.filters.category = this.adsSelectedFilterCategory
        const filters = this.filters;
        const page = 1;
        this.loadAdsByFilter({ filters, page });
      } else {
        toast.warning('Alguns campos de filtragem precisam de sua atenção.\nVeja as observações acima do botão "Buscar".', { timeout: 6000 });
      }
    },
    displayAdsFilters() {
      if (this.userActive) {
        this.$store.dispatch('loadStates');
        this.$store.commit('toggleShowAdsFilter');
      } else {
        toast.warning('Faça login para ter acesso aos filtros.', { timeout: 6000 });
      }
    },
    closeAdCategoryFilter() {
      this.$store.commit('setAdsSelectedFilterCategory', null);
      if (this.showAdsFilters) this.$store.commit('toggleShowAdsFilter');
      const filters = { category: null, state: null, city: null, title: null, description: null, min: 0, max: 0 };
      const page = 1;
      this.loadAdsByFilter({ filters, page });
      this.$store.commit('toggleShowAdsFilterPanel');
    },
    validateFilters() {
      this.correctionsNeeded = [];
      // Verificar se o estado e a cidade são selecionados corretamente
      if (this.filters.state && typeof this.filters.state.name !== "string") {
        this.correctionsNeeded.push("Por favor, selecione um estado válido");
      }

      if (this.filters.city && typeof this.filters.city.name !== "string") {
        this.correctionsNeeded.push("Por favor, selecione uma cidade válida");
      }

      const regexTitle = /^[a-zà-úA-ZÀ-Ú0-9\s.,-]{5,50}$/

      // Verificar se o título e a descrição são cadeias de caracteres e não estão vazios
      if (this.filters.title && !regexTitle.test(this.filters.title)) {
        this.correctionsNeeded.push("O título deve conter entre 3 a 40 caracteres, apenas letras maiúsculas e minúsculas, caracteres acentuados, números e espaços em branco.");
      }

      const regexDescription = /^[a-zA-Z0-9À-ÿ ,;:.!?@&'"/\\()\\[\]-]+$/;

      if (this.filters.description && !regexDescription.test(this.filters.description)) {
        this.correctionsNeeded.push("A descrição deve conter entre 20 a 5000 caracteres. Letras, números, espaços e alguns caracteres especiais");
      }

      // Verificar se o valor mínimo e máximo são números e o valor mínimo é menor que o máximo
      if (this.filters.min && typeof this.filters.min !== "number") {
        this.correctionsNeeded.push("Por favor, insira um valor mínimo válido");
      }

      if (this.filters.max && typeof this.filters.max !== "number") {
        this.correctionsNeeded.push("Por favor, insira um valor máximo válido");
      }

      if (this.filters.min !== null && this.filters.min !== undefined &&
        this.filters.max !== null && this.filters.max !== undefined &&
        this.filters.min > this.filters.max) {
        this.correctionsNeeded.push("O valor mínimo deve ser menor ou igual que o valor máximo");
      }

      // Se houver erros, retornar os erros
      if (this.correctionsNeeded.length > 0) {
        this.isValidated = false;
      } else {
        this.isValidated = true;
      }
    },
  },
  computed: {
    ...mapState(['user', 'userActive', 'showAdsFilters', 'adsSelectedFilterCategory']),
    ...mapGetters(['getAdsSelectedFilterCategory', 'getIsBacker']),
  }
});

</script>
  
<style scoped>

.close_ad {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 10px;
}

.title__filters {
  font-size: 26px;
  text-align: center;
  padding: 30px 0 30px 0;
}

.filter-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0 0 16px 0;
  font-size: 18px;
  background-color: var(--azul-scrollbarBkg-cv);
}

.filter-component label {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 200px;
  text-align: left;
}

.filter-component select,
.filter-component input[type="image"] {
  width: 320px;
  height: 38px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 16px;
}

.filter__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.filter__category {
  width: 320px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 16px;

  background: var(--branco-cv);

  --vs-border-style: none;

  --vs-controls-color: var(--azul-scrollbarBkg-cv) --vs-border-color: var(--branco-cv);

  --vs-dropdown-bg: var(--azul-escuro-cv);
  --vs-dropdown-color: var(--branco-cv);
  --vs-dropdown-option-color: var(--branco-cv);

  --vs-selected-bg: var(--branco-cv);
  --vs-selected-color: var(--preto-cv);

  --vs-search-input-color: var(--preto-cv);
  --vs-search-input-placeholder-color: var(--cinza-cv);

  --vs-dropdown-option--active-bg: var(--branco-cv);
  --vs-dropdown-option--active-color: var(--azul-scrollbarBkg-cv)
}

.filter__selector,
.filter__text-field {
  width: 320px;
  height: 40px;
  border: none;
  border-radius: 5px;
  font-size: 16px;

  background: var(--branco-cv);

  --vs-border-style: none;

  --vs-controls-color: var(--azul-scrollbarBkg-cv) --vs-border-color: var(--branco-cv);

  --vs-dropdown-bg: var(--azul-escuro-cv);
  --vs-dropdown-color: var(--branco-cv);
  --vs-dropdown-option-color: var(--branco-cv);

  --vs-selected-bg: var(--branco-cv);
  --vs-selected-color: var(--preto-cv);

  --vs-search-input-color: var(--preto-cv);
  --vs-search-input-placeholder-color: var(--cinza-cv);

  --vs-dropdown-option--active-bg: var(--branco-cv);
  --vs-dropdown-option--active-color: var(--azul-scrollbarBkg-cv);
  color: #eeee;
}

.title__text-field,
.description__text-field,
.valueMin__text-field,
.valueMax__text-field {
  width: 300px;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: var(--branco-cv);
}

.state__city,
.title__description,
.valueMin__valueMax {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.state,
.city,
.title,
.description,
.valueMin,
.valueMax {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.corretions__messages {
  padding: 10px 0 10px 0;
}

.corrections {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  gap: 6px;
  color: var(--laranja-cv);
}

.button__div {
  display: flex;
  justify-content: center;
}

.button__filters {
  font-family: Ubuntu;
  font-size: 18px;
  width: 130px;
  align-items: center;
  justify-content: center;
  background-color: var(--branco-cv);
  margin: 16px 20px 16px 0px;
  padding: 12px 2px 12px 2px;
  color: var(--azul-escuro-cv);
  border-radius: 4px;
  border-style: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
}

.button__filters:hover {
  background-color: var(--bege-cv);
  color: var(--azul-escuro-cv);
}

.button__search {
  font-family: Ubuntu;
  font-size: 18px;
  width: 130px;
  align-items: center;
  justify-content: center;
  background-color: var(--branco-cv);
  margin: 16px 20px 16px 0px;
  padding: 12px 2px 12px 2px;
  color: var(--azul-escuro-cv);
  border-radius: 4px;
  border-style: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
}

.button__search:hover {
  background-color: var(--bege-cv);
  color: var(--azul-escuro-cv);
}
</style>
  
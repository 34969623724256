<template>
    <form @submit="onSubmit" class="forgotPasswordForm" id="forgotPasswordForm">
        <div class="title_forgotPassword">Solicitação de redefinição de senha</div>
        <label for="userEmail">Endereço de e-mail</label>
        <input v-model="userEmail" type="email" id="userEmail-login" name="userEmail"
            placeholder="Digite seu endereço de e-mail" required>
        <div>
            <button class="button__form" type="submit">Enviar</button>
            <button class="button__form" type="reset" @click="hideForgotPasswordForm">Cancelar</button>

            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate
                :size="50"
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'forgotPaswordComponent',
    methods: {
        hideForgotPasswordForm() {
            this.$store.commit('hideAllContentPanel');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
        },
        resetForm() {
            this.userEmail = null;
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            const userEmail = {
                email: this.userEmail,
            };

            try {
                await this.$store.dispatch('forgotPassword', userEmail);
                this.resetForm();
                this.$store.commit('touggleShowForgotPassword');

            } catch (error) {
                console.log(error);
            } finally {
                this.isSubmitting = false;
            }
        }
    },
    data() {
        return {
            userEmail: null,
            isSubmitting: false,
        }
    },
})

</script>

<style scoped>
.title_forgotPassword {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}

.forgotPasswordForm {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 10px;
    padding: 20px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.forgotPasswordForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.forgotPasswordForm select,
.forgotPasswordForm input[type="email"],
.forgotPasswordForm input[type="text"] {
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.forgotPasswordForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
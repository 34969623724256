<template>
  <div v-if="this.$store.state.adsInterestedQuantity > 0" class="list_adsInterested">
    <p class="list_title">Interessados em seus anúncios</p>
    <p class="text__info">Há duas formas de corresponder ao interesse em seu aúncio.<br>
      Se você clicar em "Aguarde meu contato" você deve usar os dados do contato para fazer o contato com ele.<br>
      Se você clicar em "Faça contato comigo", o seu endereço de e-mail e número de celular serão exibidos para o interessado no anúncio.
    </p>
    <p class="text__info">As novas sinalizações de interessados em seus anúncios, "que forem correspondidas", permanecerão
      listadas por 30 dias.</p>
    <p class="text__info">As novas sinalizações, "que não forem correspondidas", permanecerão listadas por 7 dias.</p>
    <ul class="adsInterested">
      <li :class="{ 'adsInterested__itens': !smallPage, 'adsInterested__itens_alt': smallPage }"
        v-for="ad in getAdsInterested" :key="ad._id">
        <div :class="{ 'ad_detail': !smallPage, 'ad_detail_alt': smallPage }">
          <img :src="adsImagePath(ad.adDetail.photo)" alt="Foto do item" class="adPic">
          <p class="adDetail_text">{{ ad.adDetail.title }}</p>
        </div>
        <div :class="{ 'interested__detail': !smallPage, 'interested__detail_alt': smallPage }">
          <img :src="profileImagePath(ad.userDetail.profileImage)" alt="Foto do interessado" class="interestedPic">
          <div :class="{ 'interestedText': !smallPage, 'interestedText__alt': smallPage }">
            <p>{{ ad.userDetail.name }}</p>
            <p>{{ ad.userDetail.email }}</p>
            <p>{{ ad.userDetail.cellPhone }}</p>
            <p>Expira em {{ expiryDate(ad) }}</p>
          </div>
        </div>
        <div :class="{ 'actions': !smallPage, 'actions__alt': smallPage }">
          <div class="action__button">
            <p class="action__orange" v-if="ad.status == 'new' || ad.status == 'contact'" @click="updateInterestedStatus(ad, 'wait')">Aguarde meu
              contato</p>

            <p class="action__green" v-if="ad.status == 'wait'" @click="updateInterestedStatus(ad, 'new')">Interessado
              aguardando</p>
          </div>
          <div class="action__button">
            <p class="action__green" v-if="ad.status == 'new' || ad.status == 'wait'" @click="updateInterestedStatus(ad, 'contact')">Faça
              contato comigo</p>

            <p class="action__green" v-if="ad.status == 'contact'" @click="updateInterestedStatus(ad, 'new')">Seu contato
              foi enviado</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div v-else class="no_ads">
    <p>No momento não há usuários interessados em seus anúncios.</p>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

const adsImageStorageLink = process.env.VUE_APP_ADS_IMAGES_URL;
const profileImageStorageLink = process.env.VUE_APP_USER_PROFILE_IMAGES_URL;

export default defineComponent({
  name: 'adsListManager',
  computed: {
    ...mapState(['selectedAd', 'smallPage']),
    ...mapGetters(['getAdsInterested']),
  },
  methods: {
    async updateInterestedStatus(ad, status) {
      const interestedToUpdate = { adId: ad.adDetail.adId, userId: ad.adDetail.adUserId, userInterestedId: ad.userDetail.userId, interestedStatus: status };

      try {
        const result = await this.$store.dispatch('updateInterestedStatus', interestedToUpdate);

        if (result.data == "Status atualizado") {
          try {
            await this.loadInterestedInAds();
          } catch (error) {
            console.log(error);
            if (error.errorMessage == "") {
              this.$store.commit('setAdsInterestedQuantity', 0);
            }
          }

          const payload = { adId: ad.adDetail.adId, userId: ad.adDetail.adUserId, userInterestedId: ad.userDetail.userId };

          if (interestedToUpdate.interestedStatus == "wait") {
            try {
              await this.$store.dispatch('sendWaitAdvertiserContact', payload);
            } catch (error) {
              console.log(error);
            }
          } else if (interestedToUpdate.interestedStatus == "contact") {
            try {
              await this.$store.dispatch('sendAdvertiserContact', payload);
            } catch (error) {
              console.log(error);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    adsImagePath(adImage) {
      const adImageName = adImage;
      const timestamp = Date.now();
      return adsImageStorageLink + adImageName + '?t=' + timestamp;
    },
    profileImagePath(profileImage) {
      const profileImageName = profileImage;
      const timestamp = Date.now();
      return profileImageStorageLink + profileImageName + '?t=' + timestamp;
    },
    expiryDate(ad) {
      let expiryDate = new Date(ad.createdAt);
      if (ad.status == "new") {
        expiryDate.setDate(expiryDate.getDate() + 7);
        return (this.formatDate(expiryDate));
      } else {
        expiryDate.setDate(expiryDate.getDate() + 30);
        return (this.formatDate(expiryDate));
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    ...mapActions(['loadInterestedInAds']),
  },
  async mounted() {
    try {
      await this.loadInterestedInAds();
    } catch (error) {
      console.log(error);
      if (error.errorMessage == "Não foram encontrados anúncios para este usuário!") {
        this.$store.commit('setAdsInterestedQuantity', 0);
      }
    }
  },
});
</script>
  

<style>
.text__info {
  font-size: 14px;
  padding: 0 16px 5px 16px;
  text-align: center;
  gap: 6px;
  color: var(--laranja-cv);
}

.list_adsInterested {
  max-height: 541px;
  /* Altere para a altura que você deseja */
  overflow-y: auto;
  /* Exibe barra de rolagem vertical se necessário */
}

.no_ads {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding: 40px 0 40px 0;
}

.actions {
  display: flex;
  flex-direction: column;
  margin: 10px 0 16px 0;
}

.actions__alt {
  display: flex;
  flex-direction: row;
  margin: 0 0 16px 0;
}

.action__button {
  display: flex;
  font-family: Ubuntu;
  font-size: 12px;
  width: 100px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background-color: var(--branco-cv);
  margin: 10px 10px 0 10px;
  padding: 2px 2px 2px 2px;
  color: var(--azul-escuro-cv);
  border-radius: 4px;
  border-style: none;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in-out;
}

.action__button:hover {
  background-color: var(--bege-cv);
  color: var(--azul-escuro-cv);
}

.adsInterested__itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 0 0;
  gap: 10px;
  background-color: var(--azul-escuro-cv);
}

.adsInterested__itens_alt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 0 0;
  gap: 10px;
  background-color: var(--azul-escuro-cv);
}

.adPic {
  display: flex;
  width: 150px;
  border-radius: 1%;
  border: solid;
  border-width: 3px;
  align-content: center;
}

.ad_detail {
  display: flex;
  width: 300px;
  align-items: center;
}

.ad_detail_alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.adDetail_text {
  padding: 5px 10px 10px 10px;
}

.interestedPic {
  display: flex;
  width: 85px;
  height: 85px;
  border-radius: 1%;
  border: solid;
  border-width: 3px;
  align-content: center;
}

.interestedText {
  display: flex;
  width: 220px;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  padding: 0 10px 0 16px;
  gap: 3px;
  background-color: var(--azul-escuro-cv);
}

.interestedText__alt {
  display: flex;
  width: 220px;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  padding: 10px 10px 0 16px;
  gap: 3px;
  background-color: var(--azul-escuro-cv);
}

.interested__detail {
  display: flex;
  flex-direction: row;
  width: 320px;
  align-items: center;
}

.interested__detail_alt {
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: center
}

.list_title {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 22px;
  padding: 0 0 16px 0;
}

.action__green {
  font-weight: bold;
  color: var(--verde-cv);
  line-height: normal;
}

.action__orange {
  font-weight: bold;
  color: var(--laranja-cv);
  line-height: normal;
}</style>
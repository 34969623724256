<template>
  <div>
    <img class="iconMobile" src="../assets/img/mobile_menu_blue.png" alt="Menu para celular" @click="visibleCategories">
  </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'mobileMenu',
    methods: {
      visibleCategories() {
        this.$store.commit('touggleShowCategories');
      },
    },
})

</script>

<style scoped>
.iconMobile {
  max-width: 40px;
  height: auto;
  margin-left: 5px;
}
</style>
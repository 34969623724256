<template>
        <div class="userProfile" v-if="user && userActive && !this.$store.state.showUserUpdate">
            <p class="userProfile__tiltle">PERFIL</p>
            <img class="userProfile__Image" @click="getUserData" :src="profileImagePath" alt="Foto do usuário">
            <p class="userProfile__name">{{ user.name.split(" ")[0] }}</p>
        </div>
        <div class="backer" v-if="getIsBacker">
            <p class="backer__text">Apoiador no</p>
            <a id="div_link" href="https://apoia.se/classivela" target="_blank">
                <img class="backer__logoApoiase" src="../assets/img/apoiase.png">
            </a>
            <p class="backer__text">{{ formatDate(user.lastContributionYearMonth) }}</p>
        </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mapState } from 'vuex';
import userProfileImageDefault from "../assets/img/userProfileImage_default.jpg";

const imageStorageLink = process.env.VUE_APP_USER_PROFILE_IMAGES_URL;

export default defineComponent({
    name: 'userProfileImage',
    data() {
        return {
            userProfileImageDefault: null,
        }
    },
    methods: {
        ...mapActions(['loadUser', 'loadUserAndActivate']),
        async getUserData() {
            await this.loadUser(); // Loads user data from database before let user updates it.
            this.$store.commit('hideAllContentPanel');
            this.$store.commit('touggleShowUserUpdate');
        },
        formatDate(yearMonth) {
            const year = yearMonth.substring(0, 4);
            const month = parseInt(yearMonth.substring(4, 6));
            const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

            return `${monthNames[month - 1]} ${year}`;
        }
    },
    computed: {
        ...mapState(['user', 'userActive']),
        ...mapGetters(['getIsBacker']),
        // Returns the users profile image storage if exist ou a default prfile image.
        profileImagePath() {
            if (this.user.profileImage) {
                const timestamp = Date.now();
                const profileImage = this.user.profileImage
                return imageStorageLink + profileImage + '?t=' + timestamp;
            } else {
                return userProfileImageDefault;
            }
        },
    }
})
</script>

<style>

.profile__backer {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-end;
  justify-content: space-between;
}

.profile__backer-alt {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
}

.userProfile {
    margin-right: 10px;
}

.userProfile__Image {
    display: block;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    margin-bottom: 10px;
    border: solid;
    border-width: 3px;
}

.userProfile__Image:hover {
    border: dotted;
    border-width: 5px;
}

.userProfile__tiltle {
    font-size: 12px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 10px;
    color: var(--branco-cv);
}

.userProfile__name {
    font-size: 12px;
    color: var(--branco-cv);
    text-align: center;
}

.backer__logoApoiase {
    max-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 0px 12px 0px 16px;
    padding: 12px 12px 12px 12px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.backer__text {
    color: var(--branco-cv);
    padding: 10px;
    font-size: 12px;
    text-align: center;
}
</style>
<template>
    <form @submit="onSubmit" class="userUpdateForm" id="userUpdateForm">
        <div class="title_userUpdate">Editar Perfil</div>
        <label for="userType">Tipo usuário</label>
        <select class="form__usertype" v-model="user.type" id="userType" name="userType" placeholder="Selecione um tipo">
            <option value="Velejador">Velejador</option>
            <option value="Profissional Autônomo">Profissional Autônomo</option>
            <option value="Representante de Empresa">Representante de Empresa</option>
        </select>

        <label for="userCompany" v-if="user.type === 'Representante de Empresa'">Nome da empresa</label>
        <input class="form__company" v-model="user.company" type="text" id="userCompany" name="userCompany"
            placeholder="Digite o nome da empresa" required v-if="user.type === 'Representante de Empresa'">

        <label for="userCompanyCnpj" v-if="user.type === 'Representante de Empresa'">CNPJ</label>
        <input class="form__company" v-model="userCompanyCnpj" type="text" id="userCompanyCnpj" name="userCompanyCnpj"
            placeholder="XX.XXX.XXX/0001-XX" required v-if="user.type === 'Representante de Empresa'">

        <label for="userName">Nome</label>
        <input class="form__name" v-model="user.name" type="text" id="userName" name="userName"
            placeholder="Digite o seu nome" required>

        <label for="userEmail">Endereço de e-mail</label>
        <p class="info__text">Em caso de troca de e-mail será feito um logoff e será necessária uma nova validação de e-mail.</p>
        <input class="form__email" v-model="user.email" type="email" id="userEmail" name="userEmail"
            placeholder="Digite o seu endereço de e-mail" required>

        <label for="userCellphone">Celular</label>
        <p class="info__text">Digite os números sequencialmente e sem espaços. Inclua código do país e o DDD da cidade. O código do Brasil é 55.</p>
        <p class="info__text">Caso queria mudar o DDD ou DDI, apague o número do celular com backspace até o parêntese, depois mova o cursor para dentro do parentese e use novamente o backspace.</p>
        <input class="form__cellphone" v-model="userCellphone" type="tel" id="userCellphone" name="userCellphone"
            placeholder="+ Cód do país  (DDD)  número - número" required>

        <label>Sua foto</label>
        <label v-if="!this.showCropper" for="userProfileImage" tabindex="0">
            <img v-if="url" :src="url" alt="Foto do usuário" class="userProfileImage">
            <img v-if="!url" :src="imageUrl" alt="Foto do usuário" class="userProfileImage">
            <p>Clique e selecione</p>
        </label>
        <input v-if="!this.showCropper" type="file" @change="triggerCrop" ref="userProfileImage" id="userProfileImage" name="userProfileImage" accept=".jpg, .jpeg"
            max-size="1000000" class="picture__input">

        <Cropper v-if="this.showCropper" class="cropper" ref="cropper" :src="tempImageUrl" @new-image="addImage" :stencil-component="CircleStencil" :stencil-props="{ aspectRatio: 1/1, minAspectRatio: 1/1, maxAspectRatio: 1/1}" />
        <p v-if="this.showCropper">Use as alças para fazer o corte ideal.</p>
        <div v-if="this.showCropper" class="button__crop" @click="confirmCrop">
            <p>Cortar</p>
        </div>

        <div class="especial__buttons">
            <div class="button__changePassword" @click="changePassword">
                Trocar Senha
            </div>
            <div class="button__changePassword" @click="deleteProfile">
                Excluir Perfil
            </div>
        </div>
        <div v-if="!this.$store.state.validUser">
            <ul>
                <li class="corrections" v-for="(correction, index) in this.$store.state.correctionsNeeded" :key="index">
                    <img :src="icon" alt="My Icon" /> {{ correction }}
                </li>
            </ul>
        </div>
        <div>
            <button :class="{ 'button__form': !is_Submitting, 'button__form_alt': is_Submitting }" type="submit" :disabled="is_Submitting">Enviar</button>
            <button class="button__form" type="reset" @click="hideUserUpdate">Cancelar</button>
            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate 
                :size="50" 
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
import icon from '@/assets/img/triangle_exclamation.svg';
import userProfileImageDefault from "../assets/img/userProfileImage_default.jpg";
import Cookies from 'js-cookie';

import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import noPic from "../assets/img/no_pic.jpg";

const toast = useToast();

const userImageURL = process.env.VUE_APP_USER_PROFILE_IMAGES_URL;
const userImageSufixe = process.env.VUE_APP_USER_PROFILE_IMAGES_SUFIXE

export default defineComponent({
    name: 'userUpdate',
    components: {
        Cropper,
    },
    ...mapState(['user']),
    setup() {
        return {
            CircleStencil,
        }
    },
    data() {
        return {
            icon,
            url: null,
            userType: null,
            userCompany: null,
            userCompanyCnpj: null,
            userName: null,
            userEmail: null,
            actualEmail: null,
            userVerified: false,
            userEmailValidationToken: "notVerified",
            userCellphone: null,
            userProfileImage: null,
            userPassword: null,
            userPasswordConfirm: null,
            showCropper: false,
            tempImageUrl: noPic,
            croppedFile: null,
            isSubmitting: false,
        }
    },
    mounted() {
        this.userCompanyCnpj = this.formatCnpj(this.user.cnpj);
        this.userCellphone = this.formatCellphone(this.user.cellPhone);
        this.actualEmail = this.user.email;
    },
    watch: {
        userCompanyCnpj(value) {
            if (value === null || typeof value === "undefined") return;

            const onlyNumbers = value.replace(/\D/g, "");
            if (onlyNumbers.length === 14) {
                const formattedCnpj = `${onlyNumbers.substring(0, 2)}.${onlyNumbers.substring(2, 5)}.${onlyNumbers.substring(5, 8)}/${onlyNumbers.substring(8, 12)}-${onlyNumbers.substring(12, 14)}`;
                this.userCompanyCnpj = formattedCnpj;
            }
        },
        userCellphone(value) {
            if (!value) return "";

            let numbers = value.replace(/\D/g, "");
            let formatted = "";

            // Se começar com zero, considera que não tem código do país
            if (numbers.startsWith("0")) {
            formatted = numbers
                .replace(/(\d{2})(\d{0,5})(\d{0,4})/, "($1) $2-$3")
                .replace(/-$/, "");
            } else {
            // Com código do país
            formatted = numbers
                .replace(/(\d{2})(\d{2})(\d{0,5})(\d{0,4})/, "+$1 ($2) $3-$4")
                .replace(/-$/, "");
            }

            this.userCellphone = formatted;
        },
    },
    computed: {
        ...mapState(['user']),
        imageUrl() {
            const timestamp = Date.now();
            const imageLink = userImageURL + this.user._id + userImageSufixe + '?t=' + timestamp;
            return imageLink
        },
        is_Submitting() {
            return (this.isSubmitting)
        },
    },
    methods: {
        formatCnpj(cnpj) {
            if (!cnpj) return '';

            const onlyNumbers = cnpj.replace(/\D/g, "");
            if (onlyNumbers.length === 14) {
                const formattedCnpj = `${onlyNumbers.substring(0, 2)}.${onlyNumbers.substring(2, 5)}.${onlyNumbers.substring(5, 8)}/${onlyNumbers.substring(8, 12)}-${onlyNumbers.substring(12, 14)}`;
                return formattedCnpj;
            }
            else return cnpj;
        },
        formatCellphone(cellphone) {
            if (!cellphone) return '';

            let numbers = cellphone.replace(/\D/g, "");
            let formatted = "";

            // Se começar com zero, considera que não tem código do país
            if (numbers.startsWith("0")) {
            formatted = numbers
                .replace(/(\d{2})(\d{0,5})(\d{0,4})/, "($1) $2-$3")
                .replace(/-$/, "");
            } else {
            // Com código do país
            formatted = numbers
                .replace(/(\d{2})(\d{2})(\d{0,5})(\d{0,4})/, "+$1 ($2) $3-$4")
                .replace(/-$/, "");
            }

            return formatted;
        },
        hideUserUpdate() {
            this.$store.commit('hideAllContentPanel');
            if (!this.$store.state.showSlider) {
                this.$store.commit('touggleShowSlider');
            }
            if (!this.$store.state.showAdsDisplay) {
                this.$store.commit('toggleShowAdsDisplay');
            }
            this.$store.commit('resetCorrectionsNeeded');
        },
        triggerCrop(event) {
            let file = event.target.files[0];
            let reader = new FileReader();

            reader.onload = () => {
                this.tempImageUrl = reader.result;
                this.showCropper = true;
            };
            
            reader.readAsDataURL(file);
        },
        async confirmCrop() {
            const { canvas } = this.$refs.cropper.getResult();
            this.image = canvas.toDataURL();

            // Converte Base64 de volta para um objeto Blob
            const blob = await (await fetch(this.image)).blob();

            // Cria um novo objeto de arquivo a partir do Blob
            this.croppedFile = new File([blob], "newFileName.png", { type: blob.type });

            this.showCropper = false;
            this.addImage(this.croppedFile);
        },
        addImage(file) {
            // Mostra a foto selecionada pelo usuário na moldura.
            if (file) {
                this.url = URL.createObjectURL(file);
                this.$store.commit('addFileImageName', file.name); 
                this.$store.commit('changeFiledImage');
            }
        },
        async getDefaultUserProfileImageFile() {
            const response = await fetch(userProfileImageDefault);
            const blob = await response.blob();
            return new File([blob], 'userProfileImage_default.jpg', { type: 'image/jpeg' });
        },
        async deleteProfile() {
            this.$store.commit('touggleShowUserDelete');
            if (this.$store.state.showUserUpdate) this.$store.commit('touggleShowUserUpdate');
        },
        async changePassword() {
            this.$store.commit('touggleShowUserChangePassword');
            if (this.$store.state.showUserUpdate) this.$store.commit('touggleShowUserUpdate');
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            let fileInput = '';

            if (this.croppedFile) {
                this.$store.commit('addFileImageName', this.croppedFile.name);
                fileInput = this.croppedFile;
            } else {
                this.$store.commit('addFileImageName', "userProfileImage_default.jpg");
                fileInput = await this.getDefaultUserProfileImageFile();
            }

            const file = fileInput;

            if (this.userType !== 'Representante de Empresa') {
                this.userCompany = 'none';
                this.userCompanyCnpj = '00.000.000/0000-00';
            }

            if (this.userType == 'Representante de Empresa' &&
                this.userCompany == 'none') {
                this.userCompany = '';
                this.userCompanyCnpj = '00.000.000/0000-00';
            }
            console.log("userCellphone: ", this.userCellphone);
            const userToCheck = {
                type: this.user.type,
                company: this.user.company,
                cnpj: this.user.cnpj,
                name: this.user.name,
                email: this.user.email,
                cellPhone: this.userCellphone,
                profileImage: this.user.profileImage,
                file: file,
            };
            
            // Chama a mutation para validar o usuário
            this.$store.commit('validateUpdateUser', userToCheck);

            const user = {
                _id: this.user._id,
                type: this.user.type,
                company: this.user.company,
                cnpj: this.user.cnpj,
                name: this.user.name,
                email: this.user.email,
                cellPhone: this.userCellphone,
                profileImage: this.user.profileImageName,
                password: this.user.password,
                verified: this.user.verified,
                isBacker: this.userIsBacker,
                lastContributionMonth: this.user.userLastContributionMonth,
                lastContributionValue: this.user.userLastContributionValue,
                emailValidationToken: this.user.emailValidationToken,
                backingFactor: this.user.backingFactor,
                createdAt: this.user.createdAt,
                updatedAt: new Date(),
            };

            if (this.$store.state.validUser) {
                if (this.actualEmail != userToCheck.email) {
                    const isAlreadySubscibed = await this.$store.dispatch('isUserEmailSubscribed', userToCheck.email);
                    
                    if (isAlreadySubscibed) {
                        this.$store.state.validUser = false;
                        this.$store.state.correctionsNeeded.push('Já existe no ClassiVela um perfil cadastrado com o endereço de e-mail informado!');
                    }
                }
            }

            if (this.$store.state.validUser) {

                const token = Cookies.get('user_token');
                this.$store.commit('setUserToUpdate', user);
                const response = await this.$store.dispatch('updateUser');
                const selectedFile = file;
                const renamedFile = new File([selectedFile], 'userProfileImage.jpg', { type: selectedFile.type });

                let image = renamedFile;

                if (this.$store.state.maintainImage) {
                    image = null;
                }
                
                try {
                    if (image) {
                        await this.$store.dispatch('uploadImage', {
                            userId: response.userUpdated._id,
                            image,
                            token
                        });
                    }
                } catch (error) {
                    console.log(error);
                }

                try {

                    const shortUserName = this.$store.state.user.name.split(" ")[0];

                    if (!this.$store.state.user.verified) {
                        const response = await this.$store.dispatch('sendValidationEmail');

                        const emailValidated = response.data.user.email;

                        toast.success(`${shortUserName}, seu perfil foi atualizado com sucesso, mas como você trocou o seu e-mail '${emailValidated}' você precisa validá-lo antes de acessar o site novamente.`, {timeout: 6000});
                        this.$store.dispatch('logoutUser');
                        this.hideUserUpdate;
                        this.$store.commit('touggleShowUserUpdate');
                    }

                    if (this.$store.state.user.verified) {
                        toast.success(`${shortUserName}, seu perfil atualizado com sucesso!`, {timeout: 6000});
                        this.hideUserUpdate;
                        this.$store.commit('touggleShowUserUpdate');
                    }

                } catch (error) {
                    console.log(error);
                } finally {
                    this.isSubmitting = false;
                }
            } else {
                toast.warning('Alguns campos do formulário precisam de sua atenção.\nVeja as observações acima do botão de envio.', {timeout: 6000});
                this.isSubmitting = false;
              }
        }
    }
})

</script>

<style scoped>
.title_userUpdate {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}

.userUpdateForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.userUpdateForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.userUpdateForm select,
.userUpdateForm input[type="image"] {
    width: 300px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.form__usertype {
    width: 300px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}

.form__company,
.form__name,
.form__email,
.form__cellphone {
    width: 300px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.userUpdateForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
}

.userProfileImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: solid;
    border-width: 5px;
}

.userProfileImage:hover {
    border: dotted;
    border-width: 5px;
}

.picture__input {
    display: none;
}

.corrections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    gap: 6px;
    color: var(--laranja-cv);
}

.info__text {
    color: var(--amarelo-cv);
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__form_alt {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    justify-content: center;
    background-color: var(--cinza-disable-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: #765d5d;
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__changePassword {
     width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 4px 2px 4px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
    margin: 30px 0px 30px 0px;
}

.button__changePassword:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__crop {
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 10px 2px 5px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    margin-left: 90px;
    margin-top: 20px;
    transition: background-color 0.1s ease-in-out;
}

.button__crop:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
<template>
    <form @submit="onSubmit" class="userSignupForm" id="userSignupForm">
        <div class="title_userSignup">Criar de perfil</div>
        <p class="form_terms">Antes de preencher o cadastro faça a leitura dos documentos "Termos e Condições de Uso" e "Política de Privacidade" disponíveis para a leitura via link no rodapé da página.</p>
        <label for="userType">Tipo usuário</label>
        <select class="form__usertype" v-model="userType" id="userType" name="userType" placeholder="Selecione um tipo">
            <option value="Velejador">Velejador</option>
            <option value="Profissional Autônomo">Profissional Autônomo</option>
            <option value="Representante de Empresa">Representante de Empresa</option>
        </select>

        <label for="userCompany" v-if="userType === 'Representante de Empresa'">Nome da empresa</label>
        <input class="form__company" v-model="userCompany" type="text" id="userCompany" name="userCompany"
        placeholder="Digite o nome da empresa" required v-if="userType === 'Representante de Empresa'">

        <label for="userCompanyCnpj" v-if="userType === 'Representante de Empresa'">CNPJ</label>
        <input class="form__company" v-model="userCompanyCnpj" type="text" id="userCompanyCnpj" name="userCompanyCnpj"
        placeholder="XX.XXX.XXX/0001-XX" required v-if="userType === 'Representante de Empresa'">

        <div class="info__text">
            <p>O seu primeiro nome estará visível nos anúncios<br>que você criar, mas somente para usuários cadastrados e logados no site.</p>
        </div>
            <label for="userName">Nome</label>
        <input class="form__name" v-model="userName" type="text" id="userName" name="userName"
            placeholder="Digite o seu nome" required>

        <label for="userEmail">Endereço de e-mail</label>
        <p class="info__text">Apoiadores no APOIA.se tem acesso a recursos exclusivos se utilizarem aqui o mesmo endereço de e-mail utilizado no cadastro lá no APOIA.se.</p>
        <input class="form__email" v-model="userEmail" type="email" id="userEmail" name="userEmail"
            placeholder="Digite o seu endereço de e-mail" required>

        <label for="userCellphone">Celular</label>
        <p class="info__text">Digite os números sequencialmente e sem espaços. Inclua código do país e o DDD da cidade. O código do Brasil é 55.</p>
        <p class="info__text">Caso queria mudar o DDD ou DDI, apague o número do celular com backspace até o parêntese, depois mova o cursor para dentro do parentese use novamente o backspace.</p>
        <input class="form__cellphone" v-model="userCellphone" type="tel" maxlength="24" id="userCellphone" name="userCellphone"
            placeholder="Exemplo 55987654321" required>

        <label>Sua foto</label>
        <label v-if="!this.showCropper" for="userProfileImage" tabindex="0">
            <img v-if="url" :src="url" alt="Foto do usuário" class="userProfileImage">
            <img v-if="!url" src="../assets/img/userProfileImage_default.jpg" alt="Foto do usuário" class="userProfileImage">
            <p>Clique, selecione e recorte.</p>
        </label>
        <input v-if="!this.showCropper" type="file" @change="triggerCrop" ref="userProfileImage" id="userProfileImage" name="userProfileImage" accept=".jpg, .jpeg"
            max-size="1000000" class="picture__input">

        <Cropper v-if="this.showCropper" class="cropper" ref="cropper" :src="tempImageUrl" @new-image="addImage" :stencil-component="CircleStencil" :stencil-props="{ aspectRatio: 1/1, minAspectRatio: 1/1, maxAspectRatio: 1/1}" />
        <p v-if="this.showCropper">Use as alças para fazer o corte ideal.</p>
        <div v-if="this.showCropper" class="button__crop" @click="confirmCrop">
            <p>Cortar</p>
        </div>

        <label for="userPassword">Senha
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input class="form_password" v-model="userPassword" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userPassword" name="userPassword"
            placeholder="Digite uma senha com letras, números e caracteres especiais" required>

        <label for="userPasswordConfirm">Senha (confirme)
            <i :class="['fa', this.$store.state.showPassword ? 'fa-eye-slash' : 'fa-eye']" @click="togglePasswordVisibility"></i>
        </label>
        <input class="form_password" v-model="userPasswordConfirm" :type="this.$store.state.showPassword ? 'text' : 'password'" id="userPasswordConfirm"
            name="userPasswordConfirm" placeholder="Repita a senha para confirmar" required>
        <div v-if="!this.$store.state.validUser">
            <ul>
                <li class="corrections" v-for="(correction, index) in this.$store.state.correctionsNeeded" :key="index">
                    <img :src="icon" alt="My Icon" /> {{ correction }}
                </li>
            </ul>
        </div>
        <div class="contact__check">
                        <input v-model="terms__checkbox" type='checkbox' id="terms__checkbox" name="contactCode"
                            placeholder="Digite o código aqui" required>
                        Eu confirmo que li, estou ciente e concordo com conteúdo dos documentos "Termos e Condições de Uso" e "Política de Privacidade", que estão disponíveis para leitura via link no rodapé do site.
                    </div>
        <div>
            <button :class="{ 'button__form': isTermsAgreed && !is_Submitting, 'button__form_alt': !isTermsAgreed || is_Submitting }" type="submit" :disabled="!isTermsAgreed || is_Submitting">Enviar</button>
            <button class="button__form" type="reset" @click="hideUserSignup">Cancelar</button>

            <v-progress-circular v-if="isSubmitting"
                color="blue-lighten-3"
                indeterminate 
                :size="50" 
                :width="10">
            </v-progress-circular>
        </div>
    </form>
</template>

<script>
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import icon from '@/assets/img/triangle_exclamation.svg';
import userProfileImageDefault from "../assets/img/userProfileImage_default.jpg";
import Cookies from 'js-cookie';
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import noPic from "../assets/img/no_pic.jpg";

const toast = useToast();

export default defineComponent({
    name: 'userSignup',
    components: {
        Cropper,
    },
    setup() {
        return {
            CircleStencil,
        }
    },
    data() {
        return {
            icon,
            userProfileImageDefault,
            url: null,
            userType: null,
            userCompany: null,
            userCompanyCnpj: null,
            userName: null,
            userEmail: null,
            userVerified: false,
            userEmailValidationToken: "notVerified",
            userCellphone: null,
            userProfileImage: null,
            userPassword: null,
            userPasswordConfirm: null,
            userIsBacker: false,
            userLastContributionYearMonth: 0,
            userLastContributionValue: 0,
            userBackingFactor: 0,
            showCropper: false,
            tempImageUrl: noPic,
            croppedFile: null,
            terms__checkbox: false,
            isSubmitting: false,
        }
    },
    watch: {
        userCompanyCnpj(value) {
            if (value === null || typeof value === "undefined") return;

            const onlyNumbers = value.replace(/\D/g, "");
            if (onlyNumbers.length === 14) {
                const formattedCnpj = `${onlyNumbers.substring(0, 2)}.${onlyNumbers.substring(2, 5)}.${onlyNumbers.substring(5, 8)}/${onlyNumbers.substring(8, 12)}-${onlyNumbers.substring(12, 14)}`;
                this.userCompanyCnpj = formattedCnpj;
            }
        },
        userCellphone(value) {
            if (!value) return "";

            let numbers = value.replace(/\D/g, "");
            let formatted = "";

            // Se começar com zero, considera que não tem código do país
            if (numbers.startsWith("0")) {
            formatted = numbers
                .replace(/(\d{2})(\d{0,5})(\d{0,4})/, "($1) $2-$3")
                .replace(/-$/, "");
            } else {
            // Com código do país
            formatted = numbers
                .replace(/(\d{2})(\d{2})(\d{0,5})(\d{0,4})/, "+$1 ($2) $3-$4")
                .replace(/-$/, "");
            }

            this.userCellphone = formatted;
        },
    },
    computed: {
        isTermsAgreed() {
            return (this.terms__checkbox)
        },
        is_Submitting() {
            return (this.isSubmitting)
        },
    },
    methods: {
        triggerCrop(event) {
            let file = event.target.files[0];
            let reader = new FileReader();

            reader.onload = () => {
                this.tempImageUrl = reader.result;
                this.showCropper = true;
            };
            
            reader.readAsDataURL(file);
        },
        async confirmCrop() {
            const { canvas } = this.$refs.cropper.getResult();
            this.image = canvas.toDataURL();

            // Converte Base64 de volta para um objeto Blob
            const blob = await (await fetch(this.image)).blob();

            // Cria um novo objeto de arquivo a partir do Blob
            this.croppedFile = new File([blob], "newFileName.png", { type: blob.type });

            this.showCropper = false;
            this.addImage(this.croppedFile);
        },
        onInput() {
            // Você pode deixar vazio se não precisar fazer nada
        },
        onCountrySelected() {
            // Você pode deixar vazio se não precisar fazer nada
        },
        hideUserSignup() {
            this.$store.commit('hideAllContentPanel');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            this.resetForm();
            this.$store.commit('resetCorrectionsNeeded');
        },
        togglePasswordVisibility() {
            this.$store.commit('togglePasswordVisibility');
        },
        addImage(file) {
            // Resetar estado do cropper
            this.showCropper = false;
            this.tempImageUrl = noPic;

            // Mostra a foto selecionada pelo usuário na moldura.
            if (file) {
                this.url = URL.createObjectURL(file);
                this.userProfileImage = file.name;
            }
        },
        resetForm() {
            this.$refs.userProfileImage.value = null;
            this.url = null;
            this.userType = null;
            this.userCompany = null;
            this.userCompanyCnpj = null;
            this.userName = null;
            this.userEmail = null;
            this.userCellphone = null;
            this.userProfileImage = "userProfileImage_default.jpg";
            this.userPassword = null;
            this.userPasswordConfirm = null;
        },
        async onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            let fileInput = '';

            if (this.croppedFile) {
                this.$store.commit('addFileImageName', this.croppedFile.name);
                fileInput = this.croppedFile;
            } else {
                this.$store.commit('addFileImageName', "userProfileImage_default.jpg");
                fileInput = await this.getDefaultUserProfileImageFile();
            }

            const file = fileInput;

            if (this.userType !== 'Representante de Empresa') {
                this.userCompany = 'none';
                this.userCompanyCnpj = '00.000.000/0000-00';
            }

            if (this.userType == 'Representante de Empresa' &&
                this.userCompany == 'none') {
                this.userCompany = '';
                this.userCompanyCnpj = '00.000.000/0000-00';
            }

            const user = {
                type: this.userType,
                company: this.userCompany,
                cnpj: this.userCompanyCnpj,
                name: this.userName,
                email: this.userEmail,
                cellPhone: this.userCellphone,
                profileImage: this.$store.state.userProfileImageName,
                password: this.userPassword,
                verified: this.userVerified,
                isBacker: this.userIsBacker,
                lastContributionYearMonth: this.userLastContributionYearMonth,
                lastContributionValue: this.userLastContributionValue,
                backingFactor: this.userBackingFactor,
                emailValidationToken: this.userEmailValidationToken,
                createdAt: new Date(),
                updatedAt: new Date(),
            };

            const userToCheck = {
                type: this.userType,
                company: this.userCompany,
                cnpj: this.userCompanyCnpj,
                name: this.userName,
                email: this.userEmail,
                cellPhone: this.userCellphone,
                profileImage: this.userProfileImage,
                file: file,
                password: this.userPassword,
                passwordConfirm: this.userPasswordConfirm,
            };

            // Chama a mutation para validar o usuário
            this.$store.commit('validateUser', userToCheck);

            if (this.$store.state.validUser) {
                const isAlreadySubscibed = await this.$store.dispatch('isUserEmailSubscribed', userToCheck.email);

                if (isAlreadySubscibed) {
                    this.$store.state.validUser = false;
                    this.$store.state.correctionsNeeded.push('Já existe no ClassiVela um perfil cadastrado com o endereço de e-mail informado!');
                }
            }

            if (this.$store.state.validUser) {

                const newUser = await this.$store.dispatch('createUser', user);
                const selectedFile = file;

                const renamedFile = new File([selectedFile], 'userProfileImage.jpg', { type: selectedFile.type });

                const image = renamedFile;
                const token = Cookies.get('user_token');

                if (image) {
                    try {
                        await this.$store.dispatch('uploadImage', {
                            userId: newUser._id,
                            image,
                            token
                        });

                    } catch (error) {
                        console.log(error);
                    }
                }

                try {
                    const response = await this.$store.dispatch('sendValidationEmail');
                    const emailToValidate = response.data.user.email;

                    this.resetForm();

                    toast.success(`${newUser.name.split(" ")[0]}, seu cadastramento no ClassiVela foi concluído com sucesso.\nMas antes de realizar o seu primeiro login, verifique a caixa de entrada do endereço de e-mail ${emailToValidate} e faça a validação do e-mail.`, {timeout: 6000});

                    this.hideUserSignup;
                    this.$store.commit('touggleShowUserSignup');

                } catch (error) {
                    console.log(error);
                } finally {
                    this.isSubmitting = false;
                }

            } else {
                toast.warning('Alguns campos do formulário precisam de sua atenção.\nVeja as observações acima do botão de envio.', {timeout: 6000});
                this.isSubmitting = false;
            }
        },
        async getDefaultUserProfileImageFile() {
            const response = await fetch(userProfileImageDefault);
            const blob = await response.blob();
            return new File([blob], 'userProfileImage_default.jpg', { type: 'image/jpeg' });
        }
    },
})

</script>

<style scoped>

.form_terms {
    color: var(--amarelo-cv);
}

.title_userSignup {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}
.userSignupForm {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 15px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.userSignupForm label {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    text-align: left;
}

.userSignupForm select,
.userSignupForm input[type="image"] {
    width: 320px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}

.form__usertype {
    width: 300px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}

.info__text {
    color: var(--amarelo-cv);
}

.form_apoiase {
    color: var(--amarelo-cv);
}

.form__company,
.form__name,
.form__email,
.form__cellphone,
.form_password {
    width: 300px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--azul-scrollbarBkg-cv);
    background-color: var(--branco-cv);
}


.tel-input-field {
    width: 320px;
    height: 38px;
    color: var(--azul-escuro-cv);
}

.vti__input {
    width: 320px;
}

.v-tel-input {
    width: 320px;
    height: 38px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
}

.userSignupForm p {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 15px;
}

.contact__check {
    color: var(--amarelo-cv);
    padding: 15px 0 0 0;
}

.userProfileImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    border: solid;
    border-width: 5px;
}

.userProfileImage:hover {
    border: dotted;
    border-width: 5px;
}

.picture__input {
    display: none;
}

.corrections {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    gap: 6px;
    color: var(--laranja-cv);
}

.button__form_alt {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    justify-content: center;
    background-color: var(--cinza-disable-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: #765d5d;
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}

.button__crop {
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    padding: 10px 2px 5px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    margin-left: 90px;
    margin-top: 20px;
    transition: background-color 0.1s ease-in-out;
}

.button__crop:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>
<template>
    <div class="component__background">
        <div class="title_loginin">E-mail de redefinição de senha inválido!</div>
        <div>
            <p>
                O e-mail de redefinição de senha não passou na validação. Tente solicitar uma nova redefinição de
                senha.
            </p>
            <br>
            <p>
                Em caso de dúvida entre em contato com o suporte: suporte@classivela.online
            </p>
        </div>
        <div>
            <button class="button__form" type="reset" @click="redefinitionInvalid">Ok</button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'redefinitionInvalid',
    methods: {
        redefinitionInvalid() {
            this.$store.commit('touggleShowRedefinitionInvalid');
            if (!this.$store.state.showSlider) this.$store.commit('touggleShowSlider');
            let cleanUrl = window.location.origin + window.location.pathname;
            window.history.pushState({}, document.title, cleanUrl);
        },
    },
})

</script>

<style scoped>
.component__background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-size: 18px;
    background-color: var(--azul-scrollbarBkg-cv);
}

.title_loginin {
    font-size: 26px;
    text-align: center;
    padding: 30px 0 30px 0;
}

.button__form {
    font-family: Ubuntu;
    font-size: 18px;
    width: 130px;
    align-items: center;
    justify-content: center;
    background-color: var(--branco-cv);
    margin: 16px 20px 16px 0px;
    padding: 12px 2px 12px 2px;
    color: var(--azul-escuro-cv);
    border-radius: 4px;
    border-style: none;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.1s ease-in-out;
}

.button__form:hover {
    background-color: var(--bege-cv);
    color: var(--azul-escuro-cv);
}
</style>